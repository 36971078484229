
//// LOCAL
//export const base_url = 'http://localhost:1337' 
//export const img_base_url = 'http://192.168.10.49:1337'  //IMAGE URL FOR SAILS SERVER

//// JDD TEST
////export const base_url = 'https://resto-back.evolutiosystem.com' 
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER

//// JDD TEST LOGI PRL
//export const base_url = 'https://jdd-back.logicielperlene.com' 
//export const img_base_url = 'https://jdd-back.logicielperlene.com'  //IMAGE URL FOR SAILS SERVER

//// PRL DG DEMO
//export const base_url = 'https://resto-back.evolutiosystem.com' 
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER

//// FATOU FROZEN
export const base_url = 'https://fatou-back.evolutiosystem.com' 
export const img_base_url = 'https://fatou-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER




//export const base_url = ' https://jdd-perlene.herokuapp.com' https://resto-back.evolutiosystem.com 

// const base_url = ' https://jdd-test.herokuapp.com'
//export const base_url ='http://logicielperlene.com/app'

//////export const img_base_url = 'http://192.168.10.49:5000'  //IMAGE URL FOR EXPRESS
///export const img_base_url = 'https://resto-img.evolutiosystem.com'  //IMAGE URL FOR ONLINE SERVER


/// ENVIRONNEMENT REEL

//// FATOU FROZEN
//export const base_url = 'https://back-fatou.perlenedg.com' 
//export const img_base_url = 'https://back-fatou.perlenedg.com'  //IMAGE URL FOR SAILS SERVER

//// LA MARTINE
//export const base_url = 'https://martine-back.evolutiosystem.com' 
//export const img_base_url = 'https://martine-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER

//// LA MARTINE
//export const base_url = 'https://martine-back.evolutiosystem.com' 
//export const img_base_url = 'https://martine-back.evolutiosystem.com'  //IMAGE URL FOR SAILS SERVER


// PARAMETRES

export const use_logiciel = 'NO' // YES or NO 
export const is_GestionMesure = 'NO' // YES or NO 