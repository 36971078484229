import React from "react";
import { Card, Form, Input,  Button, Modal, Select, message, Popconfirm, DatePicker, Radio } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, Spinner } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import 'moment/locale/fr-ca';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../../services/services';
//import { authenticationService } from "../../../services/authentication-service";
import { use_logiciel } from '../../../env';

//import { numberWithCommas } from '../../../helpers/index';



//const currentUser = authenticationService.currentUserValue

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

//const confirm = Modal.confirm;

//const FormItem = Form.Item;

const moment = require('moment')

//const dateFormat = 'DD/MM/YYYY';
//const { RangePicker } = DatePicker;



class Mesures extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isDetailsArticleVisible: false,
        isValidationValidation: false,

        formLayout: 'horizontal',

        clients: [],
        //agences: [],
        gender: 'M',
        raisonSociale: '',
        codClient: '',
        mentionClient: 'NOUVEAU CLIENT',
        typeClient: 'CLIENT ORDINAIRE',
        usuel: '1',
        dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        ifu: '',
        observation: '',
        numeroCarte: '',

        waitingEndMsg: '',
        isLoading: true,
        agences: [],

        successMessage: "",
        successMessageUpdate: "",
        errMessage: "",
        vertical: "top",
        horizontal: "center",
        errOpen: false,
        open: false,
        modalIsOpen: false,
        modalUpdateFIsOpen: false,
        modalUpdateHIsOpen: false,
        modalDeleteItem: false,
        modalHomme: false,
        modalDame: false,
        modalDetailsHIsOpen: false,
        modalDetailsFIsOpen: false,
        //successMessage: '',
        //errMessage: '',
        value: 'M',
        //errOpen: false,
        idMesure: '',
        mesures: [],
        notification: '',
        notificationColor: '',
        nomPrenom: '',
        sexe: '',
        telDomicile: '',
        telBureau: '',
        telPortable: '',
        email: '',

        dosCH: 0,
        manchesCourteCH: 0,
        manchesLongueCH: 0,
        carrureCH: 0,
        hauteurMachureCH: 0,
        longueurCH: 0,
        poitrineCH: 0,
        tailleCH: 0,
        bassinCH: 0,
        courCH: 0,
        dosAH: 0,
        manchesCourteAH: 0,
        manchesLongueAH: 0,
        carrureAH: 0,
        hauteurMachureAH: 0,
        longueurAH: 0,
        poitrineAH: 0,
        tailleAH: 0,
        bassinAH: 0,
        courAH: 0,
        ceinturePH: 0,
        fessePH: 0,
        caissePH: 0,
        longueurGenouxPH: 0,
        longueurTotalePH: 0,
        basPH: 0,
        dosCF: 0,
        epauleCF: 0,
        manchesTrois: 0,
        manchesCourteCF: 0,
        manchesLongueCF: 0,
        hauteurPoitrineCF: 0,
        longueurTailleDevantCF: 0,
        longueurTailleDosCF: 0,
        longueurTotaleCF: 0,
        longueurGenouxCF: 0,
        longueurRobeCF: 0,
        tourPoitrineCF: 0,
        tourTailleCF: 0,
        tourBassinCF: 0,
        tourCouCF: 0,
        tourBrasCF: 0,
        ceinturePF: 0,
        fessePF: 0,
        cuissePF: 0,
        longueurGenouxPF: 0,
        longueurJupeCourtePF: 0,
        longueurJupeLonguePF: 0,
        longueurPantalonPF: 0,
        longueurJupePF: 0,
        basPF: 0,

        sexeClient: '',
    }

    //// CHRISTIANT DEBUT

    handleToggle = (row) => {
        console.log('ROW', row)
        if (row.gender === 'M') {
            this.setState({
                modalUpdateHIsOpen: !this.state.modalUpdateHIsOpen,
                nomClient: row.raisonSociale,
                codClient: row.codClient,
                row,
                //idMesure: row.id,
                //nomPrenom: row.nomPrenom,
                //sexe: 'M',
                //telDomicile: row.telDomicile,
                //telBureau: row.telBureau,
                //telPortable: row.telPortable,
                //email: row.email,
                dosCH: row.chemiseH && row.chemiseH.dos,
                manchesCourteCH: row.chemiseH && row.chemiseH.manchesCourte,
                manchesLongueCH: row.chemiseH && row.chemiseH.manchesLongue,
                carrureCH: row.chemiseH && row.chemiseH.carrure,
                hauteurMachureCH: row.chemiseH && row.chemiseH.hauteurMachure,
                longueurCH: row.chemiseH && row.chemiseH.longueur,
                poitrineCH: row.chemiseH && row.chemiseH.poitrine,
                tailleCH: row.chemiseH && row.chemiseH.taille,
                bassinCH: row.chemiseH && row.chemiseH.bassin,
                courCH: row.chemiseH && row.chemiseH.cour,
                dosAH: row.abacosteH && row.abacosteH.dos,
                manchesCourteAH: row.abacosteH && row.abacosteH.manchesCourte,
                manchesLongueAH: row.abacosteH && row.abacosteH.manchesLongue,
                carrureAH: row.abacosteH && row.abacosteH.carrure,
                hauteurMachureAH: row.abacosteH && row.abacosteH.hauteurMachure,
                longueurAH: row.abacosteH && row.abacosteH.longueur,
                poitrineAH: row.abacosteH && row.abacosteH.poitrine,
                tailleAH: row.abacosteH && row.abacosteH.taille,
                bassinAH: row.abacosteH && row.abacosteH.bassin,
                courAH: row.abacosteH && row.abacosteH.cour,
                ceinturePH: row.pantalonH && row.pantalonH.ceinture,
                fessePH: row.pantalonH && row.pantalonH.fesse,
                caissePH: row.pantalonH && row.pantalonH.caisse,
                longueurGenouxPH: row.pantalonH && row.pantalonH.longueurGenoux,
                longueurTotalePH: row.pantalonH && row.pantalonH.longueurTotale,
                basPH: row.pantalonH && row.pantalonH.bas,
            })

        } if (row.gender === 'F') {
            this.setState({
                modalUpdateFIsOpen: !this.state.modalUpdateFIsOpen,
                row,
                //idMesure: row.id,
                codClient: row.codClient,
                nomClient: row.raisonSociale,
                //sexe: row.sexe,
                //telDomicile: row.telDomicile,
                //telBureau: row.telBureau,
                //telPortable: row.telPortable,
                //email: row.email,
                dosCF: row.chemiseF && row.chemiseF.dos,
                epauleCF: row.chemiseF && row.chemiseF.epaule,
                manchesTrois: row.chemiseF && row.chemiseF.manchesTrois,
                manchesCourteCF: row.chemiseF && row.chemiseF.manchesCourte,
                manchesLongueCF: row.chemiseF && row.chemiseF.manchesLongue,
                hauteurPoitrineCF: row.chemiseF && row.chemiseF.hauteurPoitrine,
                longueurTailleDevantCF: row.chemiseF && row.chemiseF.longueurTailleDevant,
                longueurTailleDosCF: row.chemiseF && row.chemiseF.longueurTailleDos,
                longueurTotaleCF: row.chemiseF && row.chemiseF.longueurTotale,
                longueurGenouxCF: row.chemiseF && row.chemiseF.longueurGenoux,
                longueurRobeCF: row.chemiseF && row.chemiseF.longueurRobe,
                tourPoitrineCF: row.chemiseF && row.chemiseF.tourPoitrine,
                tourTailleCF: row.chemiseF && row.chemiseF.tourTaille,
                tourBassinCF: row.chemiseF && row.chemiseF.tourBassin,
                tourCouCF: row.chemiseF && row.chemiseF.tourCou,
                tourBrasCF: row.chemiseF && row.chemiseF.tourBras,
                ceinturePF: row.pantalonF && row.pantalonF.ceinture,
                fessePF: row.pantalonF && row.pantalonF.fesse,
                cuissePF: row.pantalonF && row.pantalonF.cuisse,
                longueurGenouxPF: row.pantalonF && row.pantalonF.longueurGenoux,
                longueurJupeCourtePF: row.pantalonF && row.pantalonF.longueurJupeCourte,
                longueurJupeLonguePF: row.pantalonF && row.pantalonF.longueurJupeLongue,
                longueurPantalonPF: row.pantalonF && row.pantalonF.longueurPantalon,
                longueurJupePF: row.pantalonF && row.pantalonF.longueurJupe,
                basPF: row.pantalonF && row.pantalonF.bas,
            })
        }

    }

    handleToggleView = (row) => {
        console.log('ROW', row)
        if (row.gender === 'M') {
            this.setState({
                modalDetailsHIsOpen: !this.state.modalDetailsHIsOpen,
                nomClient: row.raisonSociale,
                codClient: row.codClient,
                row,
                //idMesure: row.id,
                //nomPrenom: row.nomPrenom,
                //sexe: 'M',
                //telDomicile: row.telDomicile,
                //telBureau: row.telBureau,
                //telPortable: row.telPortable,
                //email: row.email,
                dosCH: row.chemiseH && row.chemiseH.dos,
                manchesCourteCH: row.chemiseH && row.chemiseH.manchesCourte,
                manchesLongueCH: row.chemiseH && row.chemiseH.manchesLongue,
                carrureCH: row.chemiseH && row.chemiseH.carrure,
                hauteurMachureCH: row.chemiseH && row.chemiseH.hauteurMachure,
                longueurCH: row.chemiseH && row.chemiseH.longueur,
                poitrineCH: row.chemiseH && row.chemiseH.poitrine,
                tailleCH: row.chemiseH && row.chemiseH.taille,
                bassinCH: row.chemiseH && row.chemiseH.bassin,
                courCH: row.chemiseH && row.chemiseH.cour,
                dosAH: row.abacosteH && row.abacosteH.dos,
                manchesCourteAH: row.abacosteH && row.abacosteH.manchesCourte,
                manchesLongueAH: row.abacosteH && row.abacosteH.manchesLongue,
                carrureAH: row.abacosteH && row.abacosteH.carrure,
                hauteurMachureAH: row.abacosteH && row.abacosteH.hauteurMachure,
                longueurAH: row.abacosteH && row.abacosteH.longueur,
                poitrineAH: row.abacosteH && row.abacosteH.poitrine,
                tailleAH: row.abacosteH && row.abacosteH.taille,
                bassinAH: row.abacosteH && row.abacosteH.bassin,
                courAH: row.abacosteH && row.abacosteH.cour,
                ceinturePH: row.pantalonH && row.pantalonH.ceinture,
                fessePH: row.pantalonH && row.pantalonH.fesse,
                caissePH: row.pantalonH && row.pantalonH.caisse,
                longueurGenouxPH: row.pantalonH && row.pantalonH.longueurGenoux,
                longueurTotalePH: row.pantalonH && row.pantalonH.longueurTotale,
                basPH: row.pantalonH && row.pantalonH.bas,
            })

        } if (row.gender === 'F') {
            this.setState({
                modalDetailsFIsOpen: !this.state.modalDetailsFIsOpen,
                row,
                //idMesure: row.id,
                codClient: row.codClient,
                nomClient: row.raisonSociale,
                //sexe: row.sexe,
                //telDomicile: row.telDomicile,
                //telBureau: row.telBureau,
                //telPortable: row.telPortable,
                //email: row.email,
                dosCF: row.chemiseF && row.chemiseF.dos,
                epauleCF: row.chemiseF && row.chemiseF.epaule,
                manchesTrois: row.chemiseF && row.chemiseF.manchesTrois,
                manchesCourteCF: row.chemiseF && row.chemiseF.manchesCourte,
                manchesLongueCF: row.chemiseF && row.chemiseF.manchesLongue,
                hauteurPoitrineCF: row.chemiseF && row.chemiseF.hauteurPoitrine,
                longueurTailleDevantCF: row.chemiseF && row.chemiseF.longueurTailleDevant,
                longueurTailleDosCF: row.chemiseF && row.chemiseF.longueurTailleDos,
                longueurTotaleCF: row.chemiseF && row.chemiseF.longueurTotale,
                longueurGenouxCF: row.chemiseF && row.chemiseF.longueurGenoux,
                longueurRobeCF: row.chemiseF && row.chemiseF.longueurRobe,
                tourPoitrineCF: row.chemiseF && row.chemiseF.tourPoitrine,
                tourTailleCF: row.chemiseF && row.chemiseF.tourTaille,
                tourBassinCF: row.chemiseF && row.chemiseF.tourBassin,
                tourCouCF: row.chemiseF && row.chemiseF.tourCou,
                tourBrasCF: row.chemiseF && row.chemiseF.tourBras,
                ceinturePF: row.pantalonF && row.pantalonF.ceinture,
                fessePF: row.pantalonF && row.pantalonF.fesse,
                cuissePF: row.pantalonF && row.pantalonF.cuisse,
                longueurGenouxPF: row.pantalonF && row.pantalonF.longueurGenoux,
                longueurJupeCourtePF: row.pantalonF && row.pantalonF.longueurJupeCourte,
                longueurJupeLonguePF: row.pantalonF && row.pantalonF.longueurJupeLongue,
                longueurPantalonPF: row.pantalonF && row.pantalonF.longueurPantalon,
                longueurJupePF: row.pantalonF && row.pantalonF.longueurJupe,
                basPF: row.pantalonF && row.pantalonF.bas,
            })
        }

    }

    handlecloseDetailsH = () => {
        this.setState({ modalDetailsHIsOpen: !this.state.modalDetailsHIsOpen })
    }

    handlecloseUpdateH = () => {
        this.setState({ modalUpdateHIsOpen: !this.state.modalUpdateHIsOpen })
    }
    handlecloseUpdateF = () => {
        this.setState({ modalUpdateFIsOpen: !this.state.modalUpdateFIsOpen })
    }

    handlecloseDetailsF = () => {
        this.setState({ modalDetailsFIsOpen: !this.state.modalDetailsFIsOpen })
    }

    handleChange = (e) => {
        console.log(this.state)
        this.setState({ [e.target.name]: e.target.value })
    }

    handleClose = () => {
        console.log('TEST',)
        this.setState({
            modalIsOpen:
                !this.state.modalIsOpen,

            codClient: '',
            nomClient: '',

            dosCH: 0,
            manchesCourteCH: 0,
            manchesLongueCH: 0,
            carrureCH: 0,
            hauteurMachureCH: 0,
            longueurCH: 0,
            poitrineCH: 0,
            tailleCH: 0,
            bassinCH: 0,
            courCH: 0,
            dosAH: 0,
            manchesCourteAH: 0,
            manchesLongueAH: 0,
            carrureAH: 0,
            hauteurMachureAH: 0,
            longueurAH: 0,
            poitrineAH: 0,
            tailleAH: 0,
            bassinAH: 0,
            courAH: 0,
            ceinturePH: 0,
            fessePH: 0,
            caissePH: 0,
            longueurGenouxPH: 0,
            longueurTotalePH: 0,
            basPH: 0,
            dosCF: 0,
            epauleCF: 0,
            manchesTrois: 0,
            manchesCourteCF: 0,
            manchesLongueCF: 0,
            hauteurPoitrineCF: 0,
            longueurTailleDevantCF: 0,
            longueurTailleDosCF: 0,
            longueurTotaleCF: 0,
            longueurGenouxCF: 0,
            longueurRobeCF: 0,
            tourPoitrineCF: 0,
            tourTailleCF: 0,
            tourBassinCF: 0,
            tourCouCF: 0,
            tourBrasCF: 0,
            ceinturePF: 0,
            fessePF: 0,
            cuissePF: 0,
            longueurGenouxPF: 0,
            longueurJupeCourtePF: 0,
            longueurJupeLonguePF: 0,
            longueurPantalonPF: 0,
            longueurJupePF: 0,
            basPF: 0,
        });
    }

    handleCloseDame = () => {
        this.setState({ modalDameIsOpen: !this.state.modalDameIsOpen });
    }
    handleCloseHomme = () => {
        this.setState({ modalHommeIsOpen: !this.state.modalHommeIsOpen });
    }
    handleTypeChange = (e) => {
        this.setState({ value: e.target.value });
        console.log(e.target.value)
    }
    handleOpenModal = () => {
        if (this.state.sexeClient === 'M') {
            this.setState({ modalHommeIsOpen: !this.state.modalHommeIsOpen });
        } else if (this.state.sexeClient === 'F') {
            this.setState({ modalDameIsOpen: !this.state.modalDameIsOpen });
        } else {
            message.warning('Veuillez définir un sexe pour ce client dans la section Client', 5)

        }
    }
    ajoutMesureHomme = () => {
        const {
            //nomPrenom,
            //sexe,
            //telDomicile,
            //telBureau,
            //telPortable,
            //email,
            dosCH,
            manchesCourteCH,
            manchesLongueCH,
            carrureCH,
            hauteurMachureCH,
            longueurCH,
            poitrineCH,
            tailleCH,
            bassinCH,
            courCH,
            dosAH,
            manchesCourteAH,
            manchesLongueAH,
            carrureAH,
            hauteurMachureAH,
            longueurAH,
            poitrineAH,
            tailleAH,
            bassinAH,
            courAH,
            ceinturePH,
            fessePH,
            caissePH,
            longueurGenouxPH,
            longueurTotalePH,
            basPH,

            //nomClient,
            codClient
        } = this.state

        if (codClient === "") {
            message.warning('Veuillez sélectionner un Client', 5)
            return;
        }
        //  console.log(this.state)

        let req = {
            codClient,
            //sexe: 'M',
            //telDomicile,
            //telBureau,
            //telPortable,
            //email,
            dosCH,
            manchesCourteCH,
            manchesLongueCH,
            carrureCH,
            hauteurMachureCH,
            longueurCH,
            poitrineCH,
            tailleCH,
            bassinCH,
            courCH,
            dosAH,
            manchesCourteAH,
            manchesLongueAH,
            carrureAH,
            hauteurMachureAH,
            longueurAH,
            poitrineAH,
            tailleAH,
            bassinAH,
            courAH,
            ceinturePH,
            fessePH,
            caissePH,
            longueurGenouxPH,
            longueurTotalePH,
            basPH,
        }
        console.log('requeste', req)
        services.addMesureHomme(req)
            .then(result => {
                console.log('resultats', result)
                this.setState({
                    codClient: "",
                    //sexe: "",
                    //telDomicile: "",
                    //telBureau: "",
                    //telPortable: "",
                    //email: "",
                    dosCH: 0,
                    manchesCourteCH: 0,
                    manchesLongueCH: 0,
                    carrureCH: 0,
                    hauteurMachureCH: 0,
                    longueurCH: 0,
                    poitrineCH: 0,
                    tailleCH: 0,
                    bassinCH: 0,
                    courCH: 0,
                    dosAH: 0,
                    manchesCourteAH: 0,
                    manchesLongueAH: 0,
                    carrureAH: 0,
                    hauteurMachureAH: 0,
                    longueurAH: 0,
                    poitrineAH: 0,
                    tailleAH: 0,
                    bassinAH: 0,
                    courAH: 0,
                    ceinturePH: 0,
                    fessePH: 0,
                    caissePH: 0,
                    longueurGenouxPH: 0,
                    longueurTotalePH: 0,
                    basPH: 0,
                })

                message.success('Mesure ajouter avec succès!', 10)
                this.setState({
                    modalHommeIsOpen: !this.state.modalHommeIsOpen,
                    modalIsOpen: !this.state.modalIsOpen,
                    //successMessage: 'Mesure Homme ajouter avec succès!',
                    //open: !this.state.open,
                })

                services.listeMesure()
                    .then(mesures => {
                        console.log('mesures', mesures)
                        this.setState({ mesures })

                        setTimeout(() => {

                            // //console.log("real articles", mesures)
                            if (!mesures || !Array.isArray(mesures)
                                || !mesures.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune mesure enrégistrée actuellement!` })
                            }
                        }, 500)

                        services.getClients()
                            .then(clients => {
                                //  //console.log('clients', clients)
                                this.setState({ clients: clients.filter(x => !mesures.some(mesure => mesure.codClient === x.codClient)) })
                            })
                    })

            })

    }
    ajoutMesureDame = () => {
        const {
            //nomPrenom,
            //sexe,
            //telDomicile,
            //telBureau,
            //telPortable,
            //email,
            dosCF,
            epauleCF,
            manchesTrois,
            manchesCourteCF,
            manchesLongueCF,
            hauteurPoitrineCF,
            longueurTailleDevantCF,
            longueurTailleDosCF,
            longueurTotaleCF,
            longueurGenouxCF,
            longueurRobeCF,
            tourPoitrineCF,
            tourTailleCF,
            tourBassinCF,
            tourCouCF,
            tourBrasCF,
            ceinturePF,
            fessePF,
            cuissePF,
            longueurGenouxPF,
            longueurJupeCourtePF,
            longueurJupeLonguePF,
            longueurPantalonPF,
            longueurJupePF,
            basPF,
            codClient
        } = this.state

        if (codClient === "") {
            message.warning('Veuillez sélectionner un Client', 5)
            return;
        }

        console.log(this.state)
        let req = {
            codClient,
            //sexe: 'F',
            //telDomicile,
            //telBureau,
            //telPortable,
            //email,
            dosCF,
            epauleCF,
            manchesTroisCF: manchesTrois,
            manchesCourteCF,
            manchesLongueCF,
            hauteurPoitrineCF,
            longueurTailleDevantCF,
            longueurTailleDosCF,
            longueurTotaleCF,
            longueurGenouxCF,
            longueurRobeCF,
            tourPoitrineCF,
            tourTailleCF,
            tourBassinCF,
            tourCouCF,
            tourBrasCF,
            ceinturePF,
            fessePF,
            cuissePF,
            longueurGenouxPF,
            longueurJupeCourtePF,
            longueurJupeLonguePF,
            longueurPantalonPF,
            longueurJupePF,
            basPF,
        }
        services.addMesureDame(req)
            .then(result => {
                console.log(result)
                this.setState({
                    codClient: "",
                    sexe: "",
                    telDomicile: "",
                    telBureau: "",
                    telPortable: "",
                    email: "",
                    dosCF: 0,
                    epauleCF: 0,
                    manchesTrois: 0,
                    manchesCourteCF: 0,
                    manchesLongueCF: 0,
                    hauteurPoitrineCF: 0,
                    longueurTailleDevantCF: 0,
                    longueurTailleDosCF: 0,
                    longueurTotaleCF: 0,
                    longueurGenouxCF: 0,
                    longueurRobeCF: 0,
                    tourPoitrineCF: 0,
                    tourTailleCF: 0,
                    tourBassinCF: 0,
                    tourCouCF: 0,
                    tourBrasCF: 0,
                    ceinturePF: 0,
                    fessePF: 0,
                    cuissePF: 0,
                    longueurGenouxPF: 0,
                    longueurJupeCourtePF: 0,
                    longueurJupeLonguePF: 0,
                    longueurPantalonPF: 0,
                    longueurJupePF: 0,
                    basPF: 0,
                })
                this.setState({
                    modalDameIsOpen: !this.state.modalDameIsOpen,
                    modalIsOpen: !this.state.modalIsOpen,
                    //successMessage: 'Mesure Dame ajouter avec succès!',
                    //open: !this.state.open,
                })
                message.success('Mesure ajouter avec succès!', 10)
                services.listeMesure()
                    .then(mesures => {
                        console.log('mesures', mesures)
                        this.setState({ mesures })

                        setTimeout(() => {

                            // //console.log("real articles", mesures)
                            if (!mesures || !Array.isArray(mesures)
                                || !mesures.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune mesure enrégistrée actuellement!` })
                            }
                        }, 500)

                        services.getClients()
                            .then(clients => {
                                //  //console.log('clients', clients)
                                this.setState({ clients: clients.filter(x => !mesures.some(mesure => mesure.codClient === x.codClient)) })
                            })
                    })

            })

    }

    modificationMesureHomme = () => {
        const {
            //idMesure,
            nomPrenom,
           // sexe,
            telDomicile,
            telBureau,
            telPortable,
            email,
            dosCH,
            manchesCourteCH,
            manchesLongueCH,
            carrureCH,
            hauteurMachureCH,
            longueurCH,
            poitrineCH,
            tailleCH,
            bassinCH,
            courCH,
            dosAH,
            manchesCourteAH,
            manchesLongueAH,
            carrureAH,
            hauteurMachureAH,
            longueurAH,
            poitrineAH,
            tailleAH,
            bassinAH,
            courAH,
            ceinturePH,
            fessePH,
            caissePH,
            longueurGenouxPH,
            longueurTotalePH,
            basPH,

            codClient
        } = this.state

        if (codClient === "") {
            message.warning('Veuillez sélectionner un Client', 5)
            return;
        }
        // console.log(this.state)

        let req = {
            codClient: codClient,
            nomPrenom,
            sexe: 'M',
            telDomicile,
            telBureau,
            telPortable,
            email,
            dosCH,
            manchesCourteCH,
            manchesLongueCH,
            carrureCH,
            hauteurMachureCH,
            longueurCH,
            poitrineCH,
            tailleCH,
            bassinCH,
            courCH,
            dosAH,
            manchesCourteAH,
            manchesLongueAH,
            carrureAH,
            hauteurMachureAH,
            longueurAH,
            poitrineAH,
            tailleAH,
            bassinAH,
            courAH,
            ceinturePH,
            fessePH,
            caissePH,
            longueurGenouxPH,
            longueurTotalePH,
            basPH,
        }
        console.log('resultats modication', req)
        services.updateMesureHomme(req)
            .then(result => {
                console.log('resultats update', result)
                this.setState({
                    codClient: "",
                    sexe: "",
                    telDomicile: "",
                    telBureau: "",
                    telPortable: "",
                    email: "",
                    dosCH: 0,
                    manchesCourteCH: 0,
                    manchesLongueCH: 0,
                    carrureCH: 0,
                    hauteurMachureCH: 0,
                    longueurCH: 0,
                    poitrineCH: 0,
                    tailleCH: 0,
                    bassinCH: 0,
                    courCH: 0,
                    dosAH: 0,
                    manchesCourteAH: 0,
                    manchesLongueAH: 0,
                    carrureAH: 0,
                    hauteurMachureAH: 0,
                    longueurAH: 0,
                    poitrineAH: 0,
                    tailleAH: 0,
                    bassinAH: 0,
                    courAH: 0,
                    ceinturePH: 0,
                    fessePH: 0,
                    caissePH: 0,
                    longueurGenouxPH: 0,
                    longueurTotalePH: 0,
                    basPH: 0,
                })
                this.setState({
                    modalUpdateHIsOpen: !this.state.modalUpdateHIsOpen,
                    successMessageUpdate: 'Modifier effectuée avec succès!',
                    //open: !this.state.open,
                })
                message.success('Modification effectuée avec succès!!', 10)
                services.listeMesure()
                    .then(mesures => {
                        console.log('mesures', mesures)
                        this.setState({ mesures })

                        setTimeout(() => {

                            // //console.log("real articles", mesures)
                            if (!mesures || !Array.isArray(mesures)
                                || !mesures.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune mesure enrégistrée actuellement!` })
                            }
                        }, 500)

                        services.getClients()
                            .then(clients => {
                                //  //console.log('clients', clients)
                                this.setState({ clients: clients.filter(x => !mesures.some(mesure => mesure.codClient === x.codClient)) })
                            })
                    })
            })

    }

    modificationMesureDame = (e) => {
        const {
            //nomPrenom,
            //sexe,
            //telDomicile,
            //telBureau,
            //telPortable,
            //email,
            dosCF,
            epauleCF,
            manchesTrois,
            manchesCourteCF,
            manchesLongueCF,
            hauteurPoitrineCF,
            longueurTailleDevantCF,
            longueurTailleDosCF,
            longueurTotaleCF,
            longueurGenouxCF,
            longueurRobeCF,
            tourPoitrineCF,
            tourTailleCF,
            tourBassinCF,
            tourCouCF,
            tourBrasCF,
            ceinturePF,
            fessePF,
            cuissePF,
            longueurGenouxPF,
            longueurJupeCourtePF,
            longueurJupeLonguePF,
            longueurPantalonPF,
            longueurJupePF,
            basPF,

            codClient
        } = this.state

        if (codClient === "") {
            message.warning('Veuillez sélectionner un Client', 5)
            return;
        }

        //console.log(this.state)
        let req = {
            codClient: codClient,
            //nomPrenom,
            //sexe: 'F',
            //telDomicile,
            //telBureau,
            //telPortable,
            //email,
            dosCF,
            epauleCF,
            manchesTroisCF: manchesTrois,
            manchesCourteCF,
            manchesLongueCF,
            hauteurPoitrineCF,
            longueurTailleDevantCF,
            longueurTailleDosCF,
            longueurTotaleCF,
            longueurGenouxCF,
            longueurRobeCF,
            tourPoitrineCF,
            tourTailleCF,
            tourBassinCF,
            tourCouCF,
            tourBrasCF,
            ceinturePF,
            fessePF,
            cuissePF,
            longueurGenouxPF,
            longueurJupeCourtePF,
            longueurJupeLonguePF,
            longueurPantalonPF,
            longueurJupePF,
            basPF,
        }
        services.updateMesureDame(req)
            .then(result => {
                console.log(result)
                this.setState({
                    codClient: "",
                    sexe: "",
                    telDomicile: "",
                    telBureau: "",
                    telPortable: "",
                    email: "",
                    dosCH: 0,
                    manchesCourteCH: 0,
                    manchesLongueCH: 0,
                    carrureCH: 0,
                    hauteurMachureCH: 0,
                    longueurCH: 0,
                    poitrineCH: 0,
                    tailleCH: 0,
                    bassinCH: 0,
                    courCH: 0,
                    dosAH: 0,
                    manchesCourteAH: 0,
                    manchesLongueAH: 0,
                    carrureAH: 0,
                    hauteurMachureAH: 0,
                    longueurAH: 0,
                    poitrineAH: 0,
                    tailleAH: 0,
                    bassinAH: 0,
                    courAH: 0,
                    ceinturePH: 0,
                    fessePH: 0,
                    caissePH: 0,
                    longueurGenouxPH: 0,
                    longueurTotalePH: 0,
                    basPH: 0,
                })
                this.setState({
                    modalUpdateFIsOpen: !this.state.modalUpdateFIsOpen,
                    //successMessageUpdate: ' Modification effectuée avec succès!',
                    // open: !this.state.open,
                })
                message.success('Modification effectuée avec succès!!', 10)

                services.listeMesure()
                    .then(mesures => {
                        console.log('mesures', mesures)
                        this.setState({ mesures })

                        setTimeout(() => {

                            // //console.log("real articles", mesures)
                            if (!mesures || !Array.isArray(mesures)
                                || !mesures.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune mesure enrégistrée actuellement!` })
                            }
                        }, 500)

                        services.getClients()
                            .then(clients => {
                                //  //console.log('clients', clients)
                                this.setState({ clients: clients.filter(x => !mesures.some(mesure => mesure.codClient === x.codClient)) })
                            })
                    })

            })
    }

    handleDelete = (row) => {
        this.setState({
            codClient: row.codClient,
            nomClient: row.raisonSociale,
            sexe: row.gender,
        })
    }

    handleDeleteMesure = () => {

        const { codClient } = this.state

        services.deleteMesure(codClient)
            .then(del => {
                //console.log('delete user', del)
                message.success('Mesure supprimer avec succès', 10)

                services.listeMesure()
                    .then(mesures => {
                        console.log('mesures', mesures)
                        this.setState({ mesures })

                        setTimeout(() => {

                            // //console.log("real articles", mesures)
                            if (!mesures || !Array.isArray(mesures)
                                || !mesures.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune mesure enrégistrée actuellement!` })
                            }
                        }, 500)

                        services.getClients()
                            .then(clients => {
                                //  //console.log('clients', clients)
                                this.setState({ clients: clients.filter(x => !mesures.some(mesure => mesure.codClient === x.codClient)) })
                            })
                    })

            })
            .catch((err) => {
                message.error(err, 10)
            });

    }
    /// FIN CHRISTIAN


    componentDidMount() {

        services.listeMesure()
            .then(mesures => {
                console.log('mesures', mesures)
                this.setState({ mesures })

                setTimeout(() => {

                    // //console.log("real articles", mesures)
                    if (!mesures || !Array.isArray(mesures)
                        || !mesures.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune mesure enrégistrée actuellement!` })
                    }
                }, 500)

                services.getClients()
                    .then(clients => {
                        //  //console.log('clients', clients)
                        this.setState({ clients: clients.filter(x => !mesures.some(mesure => mesure.codClient === x.codClient)) })
                    })
            })

    }



    handleAddOpen = () => {
        this.setState({
            dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            codClient: '',
            observation: '',
            modRegl: 'Cash',
            codmod: 'ESPECES',
            montantRemise: 0,
            fraisTransport: 0,
            autreFrais: 0,
            tauxAib: 0,
            totalTva: 0,
            netHT: 0,
            tauxRemise: 0,
            netCommercial: 0,
            montantTtc: 0,
            montantAib: 0,
            netaPayer: 0,
            montFact: 0,
            refFact: '',
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
            loading: false,

        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codCat: '',

            libCat: '',
            loading: false,

        })
    }


    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,

            numFact: row.numFact,
            dateFact: row.dateFact,
            codClient: row.codClient,
            observation: row.observation,
            modRegl: row.modRegl,
            codmod: row.codmod,
            montantRemise: row.montantRemise,
            fraisTransport: row.fraisTransport,
            autreFrais: row.autreFrais,
            tauxAib: row.tauxAib,
            totalTva: row.totalTva,
            netHT: row.netHT,
            tauxRemise: row.tauxRemise,
            netCommercial: row.netCommercial,
            montantTtc: row.montantTtc,
            montantAib: row.montantAib,
            netaPayer: row.netaPayer,
            montFact: row.montFact,
            refFact: row.refFact,
            nomAgence: row && row.agences ? row.agences.nomAgence : '',
            nomAgent: `${row && row.userInfo ? row.userInfo.prenomAgent : ''} ${row && row.userInfo ? row.userInfo.nomAgent : ''}`,

            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        //RESTE CETTE PARTIE AVEC LES AGENTS
                        //numFact: facture.id,

                        puRemise: details.puRemise,
                        qtCommander: details.qtCommander,
                        codTva: details.codTva,
                        tauxTva: details.tauxTva,
                        puAchat: details.puAchat,
                        puBrut: details.puBrut,
                        //puRemise: details.puRemise,
                        puUtilise: details.puUtilise,
                        codAgence: details.codAgence,
                        //codProd: details.codProd,

                        //qtEntree: details.qtEntree,
                        //qteStock: details && details.stock ? details.stock.qteStock : '',
                        //qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
        });
    }


    //clients
    handleCancelAddClient = () => {
        this.setState({
            isAddClientModalVisible: !this.state.isAddClientModalVisible,
            codClient: '',
            raisonSociale: '',
            ifu: '',
            adresse: '',
            contact: '',
            contact2: '',
            gender: 'M',
            usuel: '1',
            mentionClient: 'NOUVEAU CLIENT',
            dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            typeClient: 'CLIENT ORDINAIRE',
            siteWeb: '',
            email: '',
            profession: '',
            numeroCarte: '',
        })
    }


    handleAddClient = () => {
        const {
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            numeroCarte,
        } = this.state


        if (raisonSociale === '') {
            message.warning('Veuillez renseigner le champ Raison sociale', 5)
            return;
        }
        if (contact === '') {
            message.warning('Veuillez renseigner le champ Téléphone', 5)
            return;
        }


        let req = {
            raisonSociale: raisonSociale.toUpperCase(),
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            numeroCarte,

        }
        services.addClient(req)
            .then(result => {
                //  //console.log(result)
                this.setState({
                    raisonSociale: '',
                    ifu: '',
                    adresse: '',
                    contact: '',
                    contact2: '',
                    gender: 'M',
                    usuel: '1',
                    mentionClient: 'NOUVEAU CLIENT',
                    dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    typeClient: 'CLIENT ORDINAIRE',
                    siteWeb: '',
                    email: '',
                    profession: '',
                    numeroCarte: '',
                    isAddClientModalVisible: !this.state.isAddClientModalVisible,

                })
                message.success('Client ajouter avec succès', 10)

                services.getClients()
                    .then(clients => {
                        this.setState({ clients })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
            });
    }


    handleChangeGender = (value) => {
        this.setState({ gender: value })
    }


    onChangeMention = e => {
        ////console.log('radio4 checked', e.target.value);
        this.setState({
            mentionClient: e.target.value,
        });
    };

    handleChangeUsuel = (value) => {
        this.setState({ usuel: value })
    }

    handleChangeDate = (date, dateString) => {
        //  //console.log(dateString, moment(dateString).format('DD/MM/YYYY'), moment(date, dateFormatList[0]));
        this.setState({ dateNaissance: dateString })
    }

    handleChangeTypeClient = (value) => {
        this.setState({ typeClient: value })
    }
    //FIN CLIENT

    //Old
    handleChangeClient = (value) => {
        //console.log('COD CLIENT', value)
        this.setState({ codClient: value })
    }

    handleChangeClient2 = (client) => {
        console.log('CLIENT', client)
        this.setState({ codClient: client.codClient, sexeClient: client.gender, nomClient: client.raisonSociale })
    }




    render() {
        const {
            //isModalVisible,
            loading,

            isAddClientModalVisible,
            //isAddModalVisible,
           // isUpdateModalVisible,
           // isListArticleVisible,
           // isDetailsArticleVisible,
           // isValidationValidation,


            codClient,
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            //usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            //numeroCarte,
            clients,

           // factures,

           // qteStock,

            //formLayout,

           // tauxAib,
            isLoading,
            waitingEndMsg,

            //startDate,
            //endDate,

            // CHRISTIAN

            modalHommeIsOpen,
            modalDameIsOpen,
            modalIsOpen,
           // modalUpdateIsOpen,
           // ModalDeleteItem,
           /// errMessage,
           // successMessage,
           // successMessageUpdate,
           // value,
           // nomPrenom,
           // sexe,
           // telDomicile,
           // telBureau,
           // telPortable,
            // email, 
            dosCH,
            manchesCourteCH,
            manchesLongueCH,
            carrureCH,
            hauteurMachureCH,
            longueurCH,
            poitrineCH,
            tailleCH,
            bassinCH,
            courCH,
            dosAH,
            manchesCourteAH,
            manchesLongueAH,
            carrureAH,
            hauteurMachureAH,
            longueurAH,
            poitrineAH,
            tailleAH,
            bassinAH,
            courAH,
            ceinturePH,
            fessePH,
            caissePH,
            longueurGenouxPH,
            longueurTotalePH,
            basPH,
            dosCF,
            epauleCF,
            manchesTrois,
            manchesCourteCF,
            manchesLongueCF,
            hauteurPoitrineCF,
            longueurTailleDevantCF,
            longueurTailleDosCF,
            longueurTotaleCF,
            longueurGenouxCF,
            longueurRobeCF,
            tourPoitrineCF,
            tourTailleCF,
            tourBassinCF,
            tourCouCF,
            tourBrasCF,
            ceinturePF,
            fessePF,
            cuissePF,
            longueurGenouxPF,
            longueurJupeCourtePF,
            longueurJupeLonguePF,
            longueurPantalonPF,
            longueurJupePF,
            basPF,
            //mesure,
            modalUpdateFIsOpen,
            modalUpdateHIsOpen,
            modalDetailsHIsOpen,
            modalDetailsFIsOpen,
            //
            nomClient,
            //sexeClient,
            mesures,

        } = this.state

        ///const titleMesureClient = `Prise de mesure pour ${nomClient}`


        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        /*const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;*/


        const columnsMesures = [
            {
                dataField: 'codClient',
                text: 'Code',
                headerAlign: 'center',
                align: 'center',
            },
            {
                dataField: 'raisonSociale',
                text: 'Nom & Prénoms',
                headerAlign: 'center',
                align: 'center',
            },
            {
                dataField: 'gender',
                text: 'Sexe',
                headerAlign: 'center',
                align: 'center',
            },
            {
                dataField: 'contact',
                text: 'Teléphone',
                headerAlign: 'center',
                align: 'center',
            },
            {
                dataField: 'contact2',
                text: 'Teléphone 2',
                headerAlign: 'center',
                align: 'center',
            },
            {
                dataField: 'adresse',
                text: 'Adresse',
                headerAlign: 'center',
                align: 'center',
            },

            {
                dataField: '',
                headerAttrs: { width: 70 },
                headerAlign: 'center',
                text: 'Actions',
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (

                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleToggleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleToggle(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Button
                                title="Valider"
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                className="gx-btn-block gx-btn-yellow"
                                size="sm"
                                onClick={() => this.handleValider(row)}
                            >
                                <i className="icon icon-check-circle-o" />
                            </Button>
                        }*/}
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title={`Êtes vous sûr de vouloir supprimer la mesure prise pour ${row.raisonSociale} ?`} onConfirm={this.handleDeleteMesure} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="sm"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>

                    );
                },
            }
        ];

        const columnsMesuresPhone = [

            {
                dataField: 'raisonSociale',
                text: 'Nom & Prénoms',
                headerAlign: 'center',
                align: 'center',
            },

            {
                dataField: 'contact',
                text: 'Teléphone',
                headerAlign: 'center',
                align: 'center',
            },


            {
                dataField: '',
                headerAlign: 'center',
                headerAttrs: { width: 70 },
                text: 'Actions',
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (

                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleToggleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleToggle(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Button
                                title="Valider"
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                className="gx-btn-block gx-btn-yellow"
                                size="sm"
                                onClick={() => this.handleValider(row)}
                            >
                                <i className="icon icon-check-circle-o" />
                            </Button>
                        }*/}
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title={`Êtes vous sûr de vouloir supprimer la mesure prise pour ${row.raisonSociale} ?`} onConfirm={this.handleDeleteMesure} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="sm"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>

                    );
                },
            }
        ];

        /*const defaultSorted = [{
            dataField: 'numFact',
            order: 'desc'
        }];*/

        /*let totalChiffre = factures && factures.length ?
            factures.reduce(function (prev, cur) {
                return prev + parseFloat(cur.montFact)
            }, 0) : 0*/


        return (
            <div>
                <Card title="Liste des Mesures">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={mesures}
                            columns={columnsMesures}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={3} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>

                                            <Col md={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                {use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&



                                                    <button style={{ marginLeft: 35 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleClose}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                }
                                            </Col>


                                        </Row>
                                        {/*mesures &&
                                            Array.isArray(mesures) &&
                                            mesures.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h3 style={{ color: "red", fontWeight: "bold" }}><u>Total Clients</u>: {(totalChiffre === 0) ? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                </Col>
                                            </Row>
                                            */}


                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            mesures &&
                                                Array.isArray(mesures) &&
                                                mesures.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    //defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement"

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={mesures}
                            columns={columnsMesuresPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={24} sm={24} className="ml-3" >

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ width: "auto", minWidth: "148%", maxWidth: "100%", display: 'flex' }}
                                                    //style={{ maxWidth: 450, width: 'auto', display: 'flex' }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            {/*<Col md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>

                                                <Select
                                                    //style={{ maxWidth: 650, width: '90%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                    value={etatCmde}
                                                    showSearch
                                                    //style={{width: 200}}
                                                    style={{ width: '90%' }}
                                                    placeholder="Filtrer par statut"
                                                    optionFilterProp="children"
                                                    onChange={this.handleChangeEtatCmde}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >

                                                    <Option value="EN COURS">EN COURS</Option>
                                                    <Option value="VALIDER">VALIDER</Option>

                                                </Select>

                                </Col>*/}

                                            {use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&

                                                <Col className="mt-2" md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                                            }

                                        </Row>
                                        {/*mesures &&
                                            Array.isArray(mesures) &&
                                            mesures.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h4 style={{ color: "red", fontWeight: "bold" }}><u>Total Client</u>: {(totalChiffre === 0) ? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h4>
                                                </Col>
                                            </Row>
                                        */}
                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {mesures &&
                                            Array.isArray(mesures) &&
                                            mesures.length > 0 ?
                                            <BootstrapTable
                                                {...props.baseProps}
                                                //bootstrap4
                                                wrapperClasses="table-responsive"
                                                //selectRow={ selectRow }
                                                //defaultSorted={defaultSorted}
                                                pagination={paginationFactory()}
                                                //defaultSorted={defaultSorted}
                                                striped
                                                hover
                                                condensed
                                                headerWrapperClasses="foo"
                                                noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                            //filter={filterFactory()}
                                            />
                                            :
                                            isLoading ?
                                                <div style={{ justifyContent: 'center' }}>

                                                    <Row style={{ justifyContent: 'center' }}>
                                                        <Col style={{ justifyContent: 'center' }}>
                                                            <Spinner type="grow" color="primary" />
                                                            <Spinner type="grow" color="secondary" />
                                                            <Spinner type="grow" color="success" />
                                                            <Spinner type="grow" color="danger" />
                                                            <Spinner type="grow" color="warning" />
                                                            <Spinner type="grow" color="info" />
                                                            <Spinner type="grow" color="light" />
                                                            <Spinner type="grow" color="dark" />
                                                        </Col>
                                                    </Row> <br />
                                                </div>
                                                : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>


                {/*Modal choix sexe*/}
                <Modal

                    width={600}
                    visible={modalIsOpen}
                    title="Veuillez sélectionner un Client pour continuer..."
                    //onOk={this.handleAddOpen}
                    onCancel={() => this.setState({ modalIsOpen: !this.state.modalIsOpen })}
                    footer={[

                        <Button key="back" type="danger" onClick={this.handleClose}>
                            Annuler
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleOpenModal}>
                            Continuer
                        </Button>,

                    ]}


                >

                    <Form className="form-horizontal">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codClient">Client</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codClient}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Rechercher un Client"
                                            optionFilterProp="children"
                                            // onChange={this.handleChangeClient}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                clients && clients.length ?
                                                    clients.map((client, k) => {
                                                        return <Option value={client.codClient} onClick={() => this.handleChangeClient2(client)}>{client.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddClient} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> Nouveau Client</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                        </Row>
                        {/*<Row className=" mb-4 d-flex justify-content-center align-items-center">
                                    <Col lg={6}>
                                        <Input type="checkbox" labelClassName="col-xs-2" value={"M"} wrapperClassName="col-xs-10" onChange={this.handleTypeChange} /><br /><br />
                                        <label className="form-check-label">Masculin</label>
                                    </Col>
                                    <Col lg={6}>
                                        <Input type="checkbox" labelClassName="col-xs-2" value={"F"} wrapperClassName="col-xs-10" onChange={this.handleTypeChange} /> <br /><br />
                                        <label className="form-check-label">Féminin</label>
                                    </Col>
                                                </Row>*/}
                    </Form>
                </Modal>

                {/*Modal ajout mesure Homme */}
                <Modal
                    width={900}
                    visible={modalHommeIsOpen}
                    title={`Prise Mesure pour ${nomClient} 👨🏽`}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseHomme}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.ajoutMesureHomme}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCloseHomme}>
                            Annuler
                        </Button>,

                    ]}

                >


                    <Form className="form-horizontal">

                        <body centered>Chemise</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="Dos" className="form-label"> Dos </Label>
                                    <Input value={dosCH} onChange={this.handleChange} type="text" name="dosCH" className="form-control" id="dosCH" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueur" className="form-label">Longeur </Label>
                                    <Input value={longueurCH} onChange={this.handleChange} type="text" name="longueurCH" className="form-control" id="longueurCH" placeholder="longeur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="poitrineCH" className="form-label"> Poitrine </Label>
                                    <Input value={poitrineCH} onChange={this.handleChange} type="text" name="poitrineCH" className="form-control" id="poitrineCH" placeholder="poitrine" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteCH" className="form-label"> Manche courte </Label>
                                    <Input value={manchesCourteCH} onChange={this.handleChange} type="text" name="manchesCourteCH" className="form-control" id="manchesCourteCH" placeholder=" Manche courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueCH" className="form-label"> Manche longue</Label>
                                    <Input value={manchesLongueCH} onChange={this.handleChange} type="text" name="manchesLongueCH" className="form-control" id="manchesLongueCH" placeholder="Manche longue" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurMachureCH" className="form-label"> Hauteur en machure</Label>
                                    <Input value={hauteurMachureCH} onChange={this.handleChange} type="text" name="hauteurMachureCH" className="form-control" id="hauteurMachureCH" placeholder=" Hauteur en machure" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="carrureCH" className="form-label"> Carrure </Label>
                                    <Input value={carrureCH} onChange={this.handleChange} type="text" name="carrureCH" className="form-control" id="carrureCH" placeholder=" carrure  " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="bassinCH" className="form-label"> Bassin  </Label>
                                    <Input value={bassinCH} onChange={this.handleChange} type="text" name="bassinCH" className="form-control" id="bassinCH" placeholder="bassin " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="courCH" className="form-label">Cour</Label>
                                    <Input value={courCH} onChange={this.handleChange} type="text" name="courCH" className="form-control" id="courCH" placeholder=" cour" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tailleCH" className="form-label">Taille</Label>
                                    <Input value={tailleCH} onChange={this.handleChange} type="text" name="tailleCH" className="form-control" id="tailleCH" placeholder=" Taille" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>ABACOSTE</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="dosAH" className="form-label"> Dos </Label>
                                    <Input value={dosAH} onChange={this.handleChange} type="text" name="dosAH" className="form-control" id="dosAH" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurAH" className="form-label">Longeur</Label>
                                    <Input value={longueurAH} onChange={this.handleChange} type="text" name="longueurAH" className="form-control" id="longueurAH" placeholder="longeur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="poitrineAH" className="form-label">Poitrine</Label>
                                    <Input value={poitrineAH} onChange={this.handleChange} type="text" className="form-control" name="poitrineAH" id="poitrineAH" placeholder="poitrine" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteAH" className="form-label"> Manche courte</Label>
                                    <Input value={manchesCourteAH} onChange={this.handleChange} type="text" className="form-control" id="manchesCourteAH" name="manchesCourteAH" placeholder=" Manche courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueAH" className="form-label"> Manche longue </Label>
                                    <Input value={manchesLongueAH} onChange={this.handleChange} type="text" name="manchesLongueAH" className="form-control" id="manchesLongueAH" placeholder="Manche longue" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurMachureAH" className="form-label">Hauteur en machure</Label>
                                    <Input value={hauteurMachureAH} onChange={this.handleChange} type="text" name="hauteurMachureAH" className="form-control" id="banknameInput" placeholder=" Hauteur en machure" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="carrureAH" className="form-label">Carrure</Label>
                                    <Input value={carrureAH} onChange={this.handleChange} type="text" name="carrureAH" className="form-control" id="carrureAH" placeholder=" carrure  " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="bassinAH" className="form-label"> Bassin </Label>
                                    <Input value={bassinAH} onChange={this.handleChange} name="bassinAH" type="text" className="form-control" id="bassinAH" placeholder="bassin " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="courAH" className="form-label">Cour</Label>
                                    <Input value={courAH} onChange={this.handleChange} type="text" name="courAH" className="form-control" id="courAH" placeholder=" cour" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tailleAH" className="form-label">Taille  </Label>
                                    <Input value={tailleAH} onChange={this.handleChange} type="text" name="tailleAH" className="form-control" id="tailleAH" placeholder=" Taille" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>Pantalon</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="ceinturePH" className="form-label">Ceinture </Label>
                                    <Input value={ceinturePH} onChange={this.handleChange} type="text" name="ceinturePH" className="form-control" id="ceinturePH" placeholder="ceinture" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurGenouxPH" className="form-label"> Longeur genoux </Label>
                                    <Input value={longueurGenouxPH} onChange={this.handleChange} type="text" name="longueurGenouxPH" className="form-control" id="longueurGenouxPH" placeholder="longeur genoux" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="fessePH" className="form-label"> Fesse </Label>
                                    <Input value={fessePH} onChange={this.handleChange} type="text" name="fessePH" className="form-control" id="fessePH" placeholder="Fesse" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurTotalePH" className="form-label">Longeur total</Label>
                                    <Input value={longueurTotalePH} onChange={this.handleChange} type="text" className="form-control" id="longueurTotalePH" name="longueurTotalePH" placeholder=" Longeur total" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="caissePH" className="form-label">Caisse </Label>
                                    <Input value={caissePH} onChange={this.handleChange} type="text" name="caissePH" className="form-control" id="caissePH" placeholder="Caisse" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="basPH" className="form-label"> Bas </Label>
                                    <Input value={basPH} onChange={this.handleChange} type="text" name="basPH" className="form-control" id="basPH" placeholder="bas" />
                                </div>
                            </Col>
                        </Row>
                    </Form>


                </Modal>

                {/*modal ajout mesure dame*/}
                <Modal
                    width={900}
                    visible={modalDameIsOpen}
                    title={`Prise Mesure pour ${nomClient} 👩🏽`}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseDame}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.ajoutMesureDame}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCloseDame}>
                            Annuler
                        </Button>,

                    ]}

                >

                    <Form className="form-horizontal">
                        <body centered>Chemise</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="dosCF" className="form-label">  Dos </Label>
                                    <Input value={dosCF} onChange={this.handleChange} type="text" name="dosCF" className="form-control" id="dosCF" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurTotaleCF" className="form-label"> Longueur Totale </Label>
                                    <Input value={longueurTotaleCF} onChange={this.handleChange} type="text" name="longueurTotaleCF" className="form-control" id="longueurTotaleCF" placeholder="longueur Totale" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="epauleCF" className="form-label"> Epaule  </Label>
                                    <Input value={epauleCF} onChange={this.handleChange} type="text" className="form-control" id="epauleCF" name="epauleCF" placeholder="Epaule" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurGenouxCF" className="form-label">  Longueur Genoux </Label>
                                    <Input value={longueurGenouxCF} onChange={this.handleChange} type="text" className="form-control" id="longueurGenouxCF" name="longueurGenouxCF" placeholder="  Longueur Genoux" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteCF" className="form-label"> Manche courte  </Label>
                                    <Input value={manchesCourteCF} onChange={this.handleChange} type="text" name="manchesCourteCF" className="form-control" id="manchesCourteCF" placeholder="Manche Courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurRobeCF" className="form-label"> Longueur Robe </Label>
                                    <Input value={longueurRobeCF} onChange={this.handleChange} type="text" id="longueurRobeCF" name="longueurRobeCF" placeholder=" Longueur Robe" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesTrois" className="form-label"> Manches <sup>3/4</sup></Label>
                                    <Input value={manchesTrois} onChange={this.handleChange} type="text" name="manchesTrois" className="form-control" id="manchesTrois" placeholder=" Manches " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="tourPoitrineCF" className="form-label">Tour de poitrine</Label>
                                    <Input value={tourPoitrineCF} onChange={this.handleChange} type="text" className="form-control" id="tourPoitrineCF" name="tourPoitrineCF" placeholder="Tour de poitrine " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueCF" className="form-label">Manches longues</Label>
                                    <Input value={manchesLongueCF} onChange={this.handleChange} type="text" className="form-control" id="manchesLongueCF" name="manchesLongueCF" placeholder=" Ma" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="tourTailleCF" className="form-label"> Tour de taille </Label>
                                    <Input value={tourTailleCF} onChange={this.handleChange} type="text" className="form-control" id="tourTailleCF" name="tourTailleCF" placeholder="Taille" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurPoitrineCF" className="form-label">  Hauteur poitrine </Label>
                                    <Input value={hauteurPoitrineCF} onChange={this.handleChange} type="text" name="hauteurPoitrineCF" className="form-control" id="hauteurPoitrineCF" placeholder="Hauteur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="tourBassinCF" className="form-label">Tour Bassin</Label>
                                    <Input value={tourBassinCF} onChange={this.handleChange} type="text" className="form-control" id="tourBassinCF" name="tourBassinCF" placeholder=" tour" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurTailleDevantCF" className="form-label"> Taille devant  {/*longueur taille devant*/}</Label>
                                    <Input value={longueurTailleDevantCF} onChange={this.handleChange} type="text" className="form-control" name="longueurTailleDevantCF" id="longueurTailleDevantCF" placeholder="Taille" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="tourCouCF" className="form-label"> Tour cou</Label>
                                    <Input value={tourCouCF} onChange={this.handleChange} type="text" className="form-control" id="tourCouCF" name="tourCouCF" placeholder="cou" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurTailleDosCF" className="form-label"> Taille dos {/*longueur taille dos*/} </Label>
                                    <Input value={longueurTailleDosCF} onChange={this.handleChange} type="text" className="form-control" name="longueurTailleDosCF" id="longueurTailleDosCF" placeholder="taille" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tourBrasCF" className="form-label">Tour bras</Label>
                                    <Input value={tourBrasCF} onChange={this.handleChange} type="text" className="form-control" id="tourBrasCF" name="tourBrasCF" placeholder="tour" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>Pantalon/Jupe</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="ceinturePF" className="form-label">Ceinture </Label>
                                    <Input value={ceinturePF} onChange={this.handleChange} type="text" name="ceinturePF" className="form-control" id="ceinturePF" placeholder="ceinture" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="cuissePF" className="form-label"> Cuisse </Label>
                                    <Input value={cuissePF} onChange={this.handleChange} type="text" className="form-control" id="cuissePF" name="cuissePF" placeholder="cuisse" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="fessePF" className="form-label">Fesse</Label>
                                    <Input value={fessePF} onChange={this.handleChange} type="text" className="form-control" id="fessePF" name="fessePF" placeholder="Fesse" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurJupeCourtePF" className="form-label">Longeur jupe courte </Label>
                                    <Input value={longueurJupeCourtePF} onChange={this.handleChange} type="text" className="form-control" id="longueurJupeCourtePF" name="longueurJupeCourtePF" placeholder=" Longeur " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurGenouxPF" className="form-label">Longueur genoux</Label>
                                    <Input value={longueurGenouxPF} onChange={this.handleChange} type="text" className="form-control" id="longueurGenouxPF" name="longueurGenouxPF" placeholder="longueur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="basPF" className="form-label">Bas</Label>
                                    <Input value={basPF} onChange={this.handleChange} type="text" className="form-control" id="basPF" name="basPF" placeholder="bas" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurJupePF" className="form-label">Longueur jupe <sup>1/2</sup></Label>
                                    <Input value={longueurJupePF} onChange={this.handleChange} type="text" className="form-control" name="longueurJupePF" id="longueurJupePF" placeholder="longueur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurJupeLonguePF" className="form-label"> Longueur jupe</Label>
                                    <Input value={longueurJupeLonguePF} onChange={this.handleChange} type="text" className="form-control" id="longueurJupeLonguePF" name="longueurJupeLonguePF" placeholder="longueur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurPantalonPF" className="form-label">Longueur Pantalon </Label>
                                    <Input value={longueurPantalonPF} onChange={this.handleChange} type="text" className="form-control" id="longueurPantalonPF" name="longueurPantalonPF" placeholder="longueur" />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                {/*Modal modification mesure Homme */}
                <Modal
                    width={900}
                    visible={modalUpdateHIsOpen}
                    title={`Modification Mesure pour ${nomClient} 👨🏽`}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handlecloseUpdateH}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.modificationMesureHomme}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handlecloseUpdateH}>
                            Annuler
                        </Button>,

                    ]}

                >
                    <Form className="form-horizontal">

                        <body centered>Chemise</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="Dos" className="form-label"> Dos </Label>
                                    <Input value={dosCH} onChange={this.handleChange} type="text" name="dosCH" className="form-control" id="dosCH" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueur" className="form-label">longeur </Label>
                                    <Input value={longueurCH} onChange={this.handleChange} type="text" name="longueurCH" className="form-control" id="longueurCH" placeholder="longeur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="poitrineCH" className="form-label"> Poitrine </Label>
                                    <Input value={poitrineCH} onChange={this.handleChange} type="text" name="poitrineCH" className="form-control" id="poitrineCH" placeholder="poitrine" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteCH" className="form-label"> Manche courte </Label>
                                    <Input value={manchesCourteCH} onChange={this.handleChange} type="text" name="manchesCourteCH" className="form-control" id="manchesCourteCH" placeholder=" Manche courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueCH" className="form-label"> Manche longue</Label>
                                    <Input value={manchesLongueCH} onChange={this.handleChange} type="text" name="manchesLongueCH" className="form-control" id="manchesLongueCH" placeholder="Manche longue" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurMachureCH" className="form-label"> Hauteur en machure</Label>
                                    <Input value={hauteurMachureCH} onChange={this.handleChange} type="text" name="hauteurMachureCH" className="form-control" id="hauteurMachureCH" placeholder=" Hauteur en machure" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="carrureCH" className="form-label"> Carrure </Label>
                                    <Input value={carrureCH} onChange={this.handleChange} type="text" name="carrureCH" className="form-control" id="carrureCH" placeholder=" carrure  " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="bassinCH" className="form-label"> Bassin  </Label>
                                    <Input value={bassinCH} onChange={this.handleChange} type="text" name="bassinCH" className="form-control" id="bassinCH" placeholder="bassin " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="courCH" className="form-label">Cour</Label>
                                    <Input value={courCH} onChange={this.handleChange} type="text" name="courCH" className="form-control" id="courCH" placeholder=" cour" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tailleCH" className="form-label">Taille</Label>
                                    <Input value={tailleCH} onChange={this.handleChange} type="text" name="tailleCH" className="form-control" id="tailleCH" placeholder=" Taille" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>ABACOSTE</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="dosAH" className="form-label"> Dos </Label>
                                    <Input value={dosAH} onChange={this.handleChange} type="text" name="dosAH" className="form-control" id="dosAH" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurAH" className="form-label">Longeur</Label>
                                    <Input value={longueurAH} onChange={this.handleChange} type="text" name="longueurAH" className="form-control" id="longueurAH" placeholder="longeur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="poitrineAH" className="form-label">Poitrine</Label>
                                    <Input value={poitrineAH} onChange={this.handleChange} type="text" className="form-control" name="poitrineAH" id="poitrineAH" placeholder="poitrine" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteAH" className="form-label"> Manche courte</Label>
                                    <Input value={manchesCourteAH} onChange={this.handleChange} type="text" className="form-control" id="manchesCourteAH" name="manchesCourteAH" placeholder=" Manche courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueAH" className="form-label"> Manche longue </Label>
                                    <Input value={manchesLongueAH} onChange={this.handleChange} type="text" name="manchesLongueAH" className="form-control" id="manchesLongueAH" placeholder="Manche longue" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurMachureAH" className="form-label">Hauteur en machure</Label>
                                    <Input value={hauteurMachureAH} onChange={this.handleChange} type="text" name="hauteurMachureAH" className="form-control" id="banknameInput" placeholder=" Hauteur en machure" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="carrureAH" className="form-label">Carrure</Label>
                                    <Input value={carrureAH} onChange={this.handleChange} type="text" name="carrureAH" className="form-control" id="carrureAH" placeholder=" carrure  " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="bassinAH" className="form-label"> Bassin </Label>
                                    <Input value={bassinAH} onChange={this.handleChange} name="bassinAH" type="text" className="form-control" id="bassinAH" placeholder="bassin " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="courAH" className="form-label">Cour</Label>
                                    <Input value={courAH} onChange={this.handleChange} type="text" name="courAH" className="form-control" id="courAH" placeholder=" cour" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tailleAH" className="form-label">Taille  </Label>
                                    <Input value={tailleAH} onChange={this.handleChange} type="text" name="tailleAH" className="form-control" id="tailleAH" placeholder=" Taille" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>Pantalon</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="ceinturePH" className="form-label">Ceinture </Label>
                                    <Input value={ceinturePH} onChange={this.handleChange} type="text" name="ceinturePH" className="form-control" id="ceinturePH" placeholder="ceinture" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurGenouxPH" className="form-label"> Longeur genoux </Label>
                                    <Input value={longueurGenouxPH} onChange={this.handleChange} type="text" name="longueurGenouxPH" className="form-control" id="longueurGenouxPH" placeholder="longeur genoux" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="fessePH" className="form-label"> Fesse </Label>
                                    <Input value={fessePH} onChange={this.handleChange} type="text" name="fessePH" className="form-control" id="fessePH" placeholder="Fesse" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurTotalePH" className="form-label">Longeur total</Label>
                                    <Input value={longueurTotalePH} onChange={this.handleChange} type="text" className="form-control" id="longueurTotalePH" name="longueurTotalePH" placeholder=" Longeur total" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="caissePH" className="form-label">Caisse </Label>
                                    <Input value={caissePH} onChange={this.handleChange} type="text" name="caissePH" className="form-control" id="caissePH" placeholder="Caisse" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="basPH" className="form-label"> Bas </Label>
                                    <Input value={basPH} onChange={this.handleChange} type="text" name="basPH" className="form-control" id="basPH" placeholder="bas" />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Modal>

                {/*Modal modification mesure Dame */}
                <Modal
                    width={900}
                    visible={modalUpdateFIsOpen}
                    title={`Modification Mesure pour ${nomClient} 👩🏽`}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handlecloseUpdateF}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.modificationMesureDame}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handlecloseUpdateF}>
                            Annuler
                        </Button>,

                    ]}

                >
                    <Form>
                        <Form className="form-horizontal">

                            <body centered>Chemise</body>
                            <br />
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="dosCF" className="form-label">  Dos </Label>
                                        <Input value={dosCF} onChange={this.handleChange} type="text" name="dosCF" className="form-control" id="dosCF" placeholder="Dos" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurTotaleCF" className="form-label"> Longueur Totale </Label>
                                        <Input value={longueurTotaleCF} onChange={this.handleChange} type="text" name="longueurTotaleCF" className="form-control" id="longueurTotaleCF" placeholder="longueur Totale" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="epauleCF" className="form-label"> Epaule  </Label>
                                        <Input value={epauleCF} onChange={this.handleChange} type="text" className="form-control" id="epauleCF" name="epauleCF" placeholder="Epaule" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurGenouxCF" className="form-label">  Longueur Genoux </Label>
                                        <Input value={longueurGenouxCF} onChange={this.handleChange} type="text" className="form-control" id="longueurGenouxCF" name="longueurGenouxCF" placeholder="  Longueur Genoux" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="manchesCourteCF" className="form-label"> Manche courte  </Label>
                                        <Input value={manchesCourteCF} onChange={this.handleChange} type="text" name="manchesCourteCF" className="form-control" id="manchesCourteCF" placeholder="Manche Courte" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurRobeCF" className="form-label"> Longueur Robe </Label>
                                        <Input value={longueurRobeCF} onChange={this.handleChange} type="text" id="longueurRobeCF" name="longueurRobeCF" placeholder=" Longueur Robe" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="manchesTrois" className="form-label"> Manches <sup>3/4</sup></Label>
                                        <Input value={manchesTrois} onChange={this.handleChange} type="text" name="manchesTrois" className="form-control" id="manchesTrois" placeholder=" Manches " />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourPoitrineCF" className="form-label">Tour de poitrine</Label>
                                        <Input value={tourPoitrineCF} onChange={this.handleChange} type="text" className="form-control" id="tourPoitrineCF" name="tourPoitrineCF" placeholder="Tour de poitrine " />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="manchesLongueCF" className="form-label">Manches longues</Label>
                                        <Input value={manchesLongueCF} onChange={this.handleChange} type="text" className="form-control" id="manchesLongueCF" name="manchesLongueCF" placeholder=" Ma" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourTailleCF" className="form-label"> Tour de taille </Label>
                                        <Input value={tourTailleCF} onChange={this.handleChange} type="text" className="form-control" id="tourTailleCF" name="tourTailleCF" placeholder="Taille" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="hauteurPoitrineCF" className="form-label">  Hauteur poitrine </Label>
                                        <Input value={hauteurPoitrineCF} onChange={this.handleChange} type="text" name="hauteurPoitrineCF" className="form-control" id="hauteurPoitrineCF" placeholder="Hauteur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourBassinCF" className="form-label">Tour Bassin</Label>
                                        <Input value={tourBassinCF} onChange={this.handleChange} type="text" className="form-control" id="tourBassinCF" name="tourBassinCF" placeholder=" tour" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurTailleDevantCF" className="form-label"> Taille devant  {/*longueur taille devant*/}</Label>
                                        <Input value={longueurTailleDevantCF} onChange={this.handleChange} type="text" className="form-control" name="longueurTailleDevantCF" id="longueurTailleDevantCF" placeholder="Taille" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourCouCF" className="form-label"> Tour cou</Label>
                                        <Input value={tourCouCF} onChange={this.handleChange} type="text" className="form-control" id="tourCouCF" name="tourCouCF" placeholder="cou" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurTailleDosCF" className="form-label"> Taille dos {/*longueur taille dos*/} </Label>
                                        <Input value={longueurTailleDosCF} onChange={this.handleChange} type="text" className="form-control" name="longueurTailleDosCF" id="longueurTailleDosCF" placeholder="taille" />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label for="tourBrasCF" className="form-label">Tour bras</Label>
                                        <Input value={tourBrasCF} onChange={this.handleChange} type="text" className="form-control" id="tourBrasCF" name="tourBrasCF" placeholder="tour" />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <body centered>Pantalon/Jupe</body>
                            <br />
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="ceinturePF" className="form-label">Ceinture </Label>
                                        <Input value={ceinturePF} onChange={this.handleChange} type="text" name="ceinturePF" className="form-control" id="ceinturePF" placeholder="ceinture" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="cuissePF" className="form-label"> Cuisse </Label>
                                        <Input value={cuissePF} onChange={this.handleChange} type="text" className="form-control" id="cuissePF" name="cuissePF" placeholder="cuisse" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="fessePF" className="form-label">Fesse</Label>
                                        <Input value={fessePF} onChange={this.handleChange} type="text" className="form-control" id="fessePF" name="fessePF" placeholder="Fesse" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurJupeCourtePF" className="form-label">Longeur jupe courte </Label>
                                        <Input value={longueurJupeCourtePF} onChange={this.handleChange} type="text" className="form-control" id="longueurJupeCourtePF" name="longueurJupeCourtePF" placeholder=" Longeur " />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurGenouxPF" className="form-label">Longueur genoux</Label>
                                        <Input value={longueurGenouxPF} onChange={this.handleChange} type="text" className="form-control" id="longueurGenouxPF" name="longueurGenouxPF" placeholder="longueur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="basPF" className="form-label">Bas</Label>
                                        <Input value={basPF} onChange={this.handleChange} type="text" className="form-control" id="basPF" name="basPF" placeholder="bas" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurJupePF" className="form-label">Longueur jupe <sup>1/2</sup></Label>
                                        <Input value={longueurJupePF} onChange={this.handleChange} type="text" className="form-control" name="longueurJupePF" id="longueurJupePF" placeholder="longueur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurJupeLonguePF" className="form-label"> Longueur jupe</Label>
                                        <Input value={longueurJupeLonguePF} onChange={this.handleChange} type="text" className="form-control" id="longueurJupeLonguePF" name="longueurJupeLonguePF" placeholder="longueur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurPantalonPF" className="form-label">Longueur Pantalon </Label>
                                        <Input value={longueurPantalonPF} onChange={this.handleChange} type="text" className="form-control" id="longueurPantalonPF" name="longueurPantalonPF" placeholder="longueur" />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Form>
                </Modal>


                {/*Modal Détails mesure Homme */}
                <Modal
                    width={900}
                    visible={modalDetailsHIsOpen}
                    title={`Détails Mesure pour ${nomClient} 👨🏽`}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handlecloseDetailsH}
                    footer={[

                        <Button key="back" type="danger" onClick={this.handlecloseDetailsH}>
                            Annuler
                        </Button>,

                    ]}

                >
                    <Form className="form-horizontal">

                        <body centered>Chemise</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="Dos" className="form-label"> Dos </Label>
                                    <Input disabled value={dosCH} onChange={this.handleChange} type="text" name="dosCH" className="form-control" id="dosCH" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueur" className="form-label">longeur </Label>
                                    <Input disabled value={longueurCH} onChange={this.handleChange} type="text" name="longueurCH" className="form-control" id="longueurCH" placeholder="longeur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="poitrineCH" className="form-label"> Poitrine </Label>
                                    <Input disabled value={poitrineCH} onChange={this.handleChange} type="text" name="poitrineCH" className="form-control" id="poitrineCH" placeholder="poitrine" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteCH" className="form-label"> Manche courte </Label>
                                    <Input disabled value={manchesCourteCH} onChange={this.handleChange} type="text" name="manchesCourteCH" className="form-control" id="manchesCourteCH" placeholder=" Manche courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueCH" className="form-label"> Manche longue</Label>
                                    <Input disabled value={manchesLongueCH} onChange={this.handleChange} type="text" name="manchesLongueCH" className="form-control" id="manchesLongueCH" placeholder="Manche longue" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurMachureCH" className="form-label"> Hauteur en machure</Label>
                                    <Input disabled value={hauteurMachureCH} onChange={this.handleChange} type="text" name="hauteurMachureCH" className="form-control" id="hauteurMachureCH" placeholder=" Hauteur en machure" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="carrureCH" className="form-label"> Carrure </Label>
                                    <Input disabled value={carrureCH} onChange={this.handleChange} type="text" name="carrureCH" className="form-control" id="carrureCH" placeholder=" carrure  " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="bassinCH" className="form-label"> Bassin  </Label>
                                    <Input disabled value={bassinCH} onChange={this.handleChange} type="text" name="bassinCH" className="form-control" id="bassinCH" placeholder="bassin " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="courCH" className="form-label">Cour</Label>
                                    <Input disabled value={courCH} onChange={this.handleChange} type="text" name="courCH" className="form-control" id="courCH" placeholder=" cour" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tailleCH" className="form-label">Taille</Label>
                                    <Input disabled value={tailleCH} onChange={this.handleChange} type="text" name="tailleCH" className="form-control" id="tailleCH" placeholder=" Taille" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>ABACOSTE</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="dosAH" className="form-label"> Dos </Label>
                                    <Input disabled value={dosAH} onChange={this.handleChange} type="text" name="dosAH" className="form-control" id="dosAH" placeholder="Dos" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurAH" className="form-label">Longeur</Label>
                                    <Input disabled value={longueurAH} onChange={this.handleChange} type="text" name="longueurAH" className="form-control" id="longueurAH" placeholder="longeur" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="poitrineAH" className="form-label">Poitrine</Label>
                                    <Input disabled value={poitrineAH} onChange={this.handleChange} type="text" className="form-control" name="poitrineAH" id="poitrineAH" placeholder="poitrine" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesCourteAH" className="form-label"> Manche courte</Label>
                                    <Input disabled value={manchesCourteAH} onChange={this.handleChange} type="text" className="form-control" id="manchesCourteAH" name="manchesCourteAH" placeholder=" Manche courte" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="manchesLongueAH" className="form-label"> Manche longue </Label>
                                    <Input disabled value={manchesLongueAH} onChange={this.handleChange} type="text" name="manchesLongueAH" className="form-control" id="manchesLongueAH" placeholder="Manche longue" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="hauteurMachureAH" className="form-label">Hauteur en machure</Label>
                                    <Input disabled value={hauteurMachureAH} onChange={this.handleChange} type="text" name="hauteurMachureAH" className="form-control" id="banknameInput" placeholder=" Hauteur en machure" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="carrureAH" className="form-label">Carrure</Label>
                                    <Input disabled value={carrureAH} onChange={this.handleChange} type="text" name="carrureAH" className="form-control" id="carrureAH" placeholder=" carrure  " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="bassinAH" className="form-label"> Bassin </Label>
                                    <Input disabled value={bassinAH} onChange={this.handleChange} name="bassinAH" type="text" className="form-control" id="bassinAH" placeholder="bassin " />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="courAH" className="form-label">Cour</Label>
                                    <Input disabled value={courAH} onChange={this.handleChange} type="text" name="courAH" className="form-control" id="courAH" placeholder=" cour" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label for="tailleAH" className="form-label">Taille  </Label>
                                    <Input disabled value={tailleAH} onChange={this.handleChange} type="text" name="tailleAH" className="form-control" id="tailleAH" placeholder=" Taille" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <body centered>Pantalon</body>
                        <br />
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="ceinturePH" className="form-label">Ceinture </Label>
                                    <Input disabled value={ceinturePH} onChange={this.handleChange} type="text" name="ceinturePH" className="form-control" id="ceinturePH" placeholder="ceinture" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurGenouxPH" className="form-label"> Longeur genoux </Label>
                                    <Input disabled value={longueurGenouxPH} onChange={this.handleChange} type="text" name="longueurGenouxPH" className="form-control" id="longueurGenouxPH" placeholder="longeur genoux" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="fessePH" className="form-label"> Fesse </Label>
                                    <Input disabled value={fessePH} onChange={this.handleChange} type="text" name="fessePH" className="form-control" id="fessePH" placeholder="Fesse" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="longueurTotalePH" className="form-label">Longeur total</Label>
                                    <Input disabled value={longueurTotalePH} onChange={this.handleChange} type="text" className="form-control" id="longueurTotalePH" name="longueurTotalePH" placeholder=" Longeur total" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="caissePH" className="form-label">Caisse </Label>
                                    <Input disabled value={caissePH} onChange={this.handleChange} type="text" name="caissePH" className="form-control" id="caissePH" placeholder="Caisse" />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label for="basPH" className="form-label"> Bas </Label>
                                    <Input disabled value={basPH} onChange={this.handleChange} type="text" name="basPH" className="form-control" id="basPH" placeholder="bas" />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Modal>

                {/*Modal Détails mesure Dame */}
                <Modal
                    width={900}
                    visible={modalDetailsFIsOpen}
                    title={`Détails Mesure pour ${nomClient} 👩🏽`}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handlecloseDetailsF}
                    footer={[

                        <Button key="back" type="danger" onClick={this.handlecloseDetailsF}>
                            Annuler
                        </Button>,

                    ]}

                >
                    <Form>
                        <Form className="form-horizontal">

                            <body centered>Chemise</body>
                            <br />
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="dosCF" className="form-label">  Dos </Label>
                                        <Input disabled value={dosCF} onChange={this.handleChange} type="text" name="dosCF" className="form-control" id="dosCF" placeholder="Dos" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurTotaleCF" className="form-label"> Longueur Totale </Label>
                                        <Input disabled value={longueurTotaleCF} onChange={this.handleChange} type="text" name="longueurTotaleCF" className="form-control" id="longueurTotaleCF" placeholder="longueur Totale" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="epauleCF" className="form-label"> Epaule  </Label>
                                        <Input disabled value={epauleCF} onChange={this.handleChange} type="text" className="form-control" id="epauleCF" name="epauleCF" placeholder="Epaule" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurGenouxCF" className="form-label">  Longueur Genoux </Label>
                                        <Input disabled value={longueurGenouxCF} onChange={this.handleChange} type="text" className="form-control" id="longueurGenouxCF" name="longueurGenouxCF" placeholder="  Longueur Genoux" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="manchesCourteCF" className="form-label"> Manche courte  </Label>
                                        <Input disabled value={manchesCourteCF} onChange={this.handleChange} type="text" name="manchesCourteCF" className="form-control" id="manchesCourteCF" placeholder="Manche Courte" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurRobeCF" className="form-label"> Longueur Robe </Label>
                                        <Input disabled value={longueurRobeCF} onChange={this.handleChange} type="text" id="longueurRobeCF" name="longueurRobeCF" placeholder=" Longueur Robe" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="manchesTrois" className="form-label"> Manches <sup>3/4</sup></Label>
                                        <Input disabled value={manchesTrois} onChange={this.handleChange} type="text" name="manchesTrois" className="form-control" id="manchesTrois" placeholder=" Manches " />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourPoitrineCF" className="form-label">Tour de poitrine</Label>
                                        <Input disabled value={tourPoitrineCF} onChange={this.handleChange} type="text" className="form-control" id="tourPoitrineCF" name="tourPoitrineCF" placeholder="Tour de poitrine " />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="manchesLongueCF" className="form-label">Manches longues</Label>
                                        <Input disabled value={manchesLongueCF} onChange={this.handleChange} type="text" className="form-control" id="manchesLongueCF" name="manchesLongueCF" placeholder=" Ma" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourTailleCF" className="form-label"> Tour de taille </Label>
                                        <Input disabled value={tourTailleCF} onChange={this.handleChange} type="text" className="form-control" id="tourTailleCF" name="tourTailleCF" placeholder="Taille" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="hauteurPoitrineCF" className="form-label">  Hauteur poitrine </Label>
                                        <Input disabled value={hauteurPoitrineCF} onChange={this.handleChange} type="text" name="hauteurPoitrineCF" className="form-control" id="hauteurPoitrineCF" placeholder="Hauteur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourBassinCF" className="form-label">Tour Bassin</Label>
                                        <Input disabled value={tourBassinCF} onChange={this.handleChange} type="text" className="form-control" id="tourBassinCF" name="tourBassinCF" placeholder=" tour" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurTailleDevantCF" className="form-label"> Taille devant  {/*longueur taille devant*/}</Label>
                                        <Input disabled value={longueurTailleDevantCF} onChange={this.handleChange} type="text" className="form-control" name="longueurTailleDevantCF" id="longueurTailleDevantCF" placeholder="Taille" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="tourCouCF" className="form-label"> Tour cou</Label>
                                        <Input disabled value={tourCouCF} onChange={this.handleChange} type="text" className="form-control" id="tourCouCF" name="tourCouCF" placeholder="cou" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurTailleDosCF" className="form-label"> Taille dos {/*longueur taille dos*/} </Label>
                                        <Input disabled value={longueurTailleDosCF} onChange={this.handleChange} type="text" className="form-control" name="longueurTailleDosCF" id="longueurTailleDosCF" placeholder="taille" />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label for="tourBrasCF" className="form-label">Tour bras</Label>
                                        <Input disabled value={tourBrasCF} onChange={this.handleChange} type="text" className="form-control" id="tourBrasCF" name="tourBrasCF" placeholder="tour" />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <body centered>Pantalon/Jupe</body>
                            <br />
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="ceinturePF" className="form-label">Ceinture </Label>
                                        <Input disabled value={ceinturePF} onChange={this.handleChange} type="text" name="ceinturePF" className="form-control" id="ceinturePF" placeholder="ceinture" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="cuissePF" className="form-label"> Cuisse </Label>
                                        <Input disabled value={cuissePF} onChange={this.handleChange} type="text" className="form-control" id="cuissePF" name="cuissePF" placeholder="cuisse" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="fessePF" className="form-label">Fesse</Label>
                                        <Input disabled value={fessePF} onChange={this.handleChange} type="text" className="form-control" id="fessePF" name="fessePF" placeholder="Fesse" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurJupeCourtePF" className="form-label">Longeur jupe courte </Label>
                                        <Input disabled value={longueurJupeCourtePF} onChange={this.handleChange} type="text" className="form-control" id="longueurJupeCourtePF" name="longueurJupeCourtePF" placeholder=" Longeur " />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurGenouxPF" className="form-label">Longueur genoux</Label>
                                        <Input disabled value={longueurGenouxPF} onChange={this.handleChange} type="text" className="form-control" id="longueurGenouxPF" name="longueurGenouxPF" placeholder="longueur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="basPF" className="form-label">Bas</Label>
                                        <Input disabled value={basPF} onChange={this.handleChange} type="text" className="form-control" id="basPF" name="basPF" placeholder="bas" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurJupePF" className="form-label">Longueur jupe <sup>1/2</sup></Label>
                                        <Input disabled value={longueurJupePF} onChange={this.handleChange} type="text" className="form-control" name="longueurJupePF" id="longueurJupePF" placeholder="longueur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurJupeLonguePF" className="form-label"> Longueur jupe</Label>
                                        <Input disabled value={longueurJupeLonguePF} onChange={this.handleChange} type="text" className="form-control" id="longueurJupeLonguePF" name="longueurJupeLonguePF" placeholder="longueur" />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="longueurPantalonPF" className="form-label">Longueur Pantalon </Label>
                                        <Input disabled value={longueurPantalonPF} onChange={this.handleChange} type="text" className="form-control" id="longueurPantalonPF" name="longueurPantalonPF" placeholder="longueur" />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Form>
                </Modal>




                <Modal
                    width={800}
                    visible={isAddClientModalVisible}
                    title="Ajouter un Client"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAddClient}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddClient}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAddClient}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="raisonSociale">Raison Sociale</Label>
                                    <Input type="text" name="raisonSociale" id="raisonSociale" value={raisonSociale} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="ifu">IFU</Label>
                                    <Input maxLength={13} type="text" name="ifu" value={ifu} id="ifu" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="numeroCarte">Numéro Carte</Label>
                                    <Input type="text" name="numeroCarte" id="numeroCarte" value={numeroCarte} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                </Row>*/}
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <Label for="dateNaissance">Date de Naissance</Label>
                                <FormGroup>

                                    <DatePicker style={{ width: '100%' }} name="dateNaissance" id="dateNaissance" value={moment(dateNaissance, dateFormatList[0])} locale={locale} onChange={this.handleChangeDate} format={dateFormatList} />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adresse">Adresse</Label>
                                    <Input type="text" name="adresse" value={adresse} onChange={this.handleChange} id="adresse" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Profession</Label>
                                    <Input type="text" name="profession" id="profession" value={profession} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact">Téléphone</Label>
                                    <Input type="text" name="contact" id="contact" value={contact} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact2">Téléphone 2</Label>
                                    <Input type="text" name="contact2" value={contact2} id="contact2" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="text" name="email" value={email} id="email" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteWeb">Site web</Label>
                                    <Input type="text" name="siteWeb" id="siteWeb" value={siteWeb} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="typeClient">Type de Client</Label>
                                    <Select
                                        value={typeClient}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner un Type"
                                        onChange={this.handleChangeTypeClient}
                                    >
                                        <Option value="CLIENT DETAILLANT">CLIENT DETAILLANT</Option>
                                        <Option value="CLIENT EN GROS">CLIENT EN GROS</Option>
                                        <Option value="CLIENT ORDINAIRE">CLIENT ORDINAIRE</Option>

                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <Label for="mentionClient">Mention Client</Label>
                                <FormGroup>

                                    <Radio.Group
                                    // options={optionsWithDisabled}
                                    // onChange={this.onChangeMention}
                                    //value={mentionClient}
                                    //optionType="button"
                                    //buttonStyle="solid"
                                    />
                                    <Radio.Group onChange={this.onChangeMention} value={mentionClient} defaultValue={mentionClient} buttonStyle="solid">
                                        <Radio.Button value="">Aucune option</Radio.Button>
                                        <Radio.Button value="NOUVEAU CLIENT">Nouveau Client</Radio.Button>
                                        <Radio.Button value="ANCIEN CLIENT">Ancien Client</Radio.Button>
                                    </Radio.Group>
                                </FormGroup>
                            </Col>
                            {/*<Col md={6}>
                                <FormGroup>
                                    <Label for="usuel">Usuel</Label>
                                    <Select

                                        value={usuel}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner une valeur"

                                        onChange={this.handleChangeUsuel}
                                    >
                                        <Option value="1">Usuel</Option>
                                        <Option value="0">Non Usuel</Option>
                                    </Select>
                                </FormGroup>
                         </Col> */}
                        </Row>


                        {/*<Row form>

              <Col md={12}>
                <FormGroup>
                  <Label for="codClient">Agences</Label>
                  <InputGroup compact>

                    <Select

                      value={codClient}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '85%' }}
                      placeholder="Selectionner Agence"
                      optionFilterProp="children"
                      onChange={this.handleChangeAgence}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        agences && agences.length ?
                          agences.map((agence, k) => {
                            return <Option value={agence.codClient}>{agence.nomAgence}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.OpenModalAddAgence} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>
                    </Row>*/}

                    </Form>
                </Modal>



            </div>
        )
    }
}


export default Mesures;
