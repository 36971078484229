import React from "react";
import { Card, Form, Input,Button, Modal, Select, message, Popconfirm,  } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { services } from '../../../services/services'
import { use_logiciel } from '../../../env'
import { authenticationService } from "../../../services/authentication-service";



//const confirm = Modal.confirm;

const FormItem = Form.Item;

const moment = require('moment')

const currentUser = authenticationService.currentUserValue


class Repartitions extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isListArticleVisibleUpdate: false,

        isDetailsArticleVisible: false,
        isDetailsArticleVisibleUpdate: false,
        isValidationValidation: false,
        repartitions: [],
        listeArticlesSelected: [],
        articles: [],
        articlesCodPro: [],
        formLayout: 'horizontal',

        usuel: "1",
        codTyp: "1",
        libCat: '',
        codeCat: '',
        emailFour: '',
        refFactFour: '',
        codAgence: '',
        modRegl: '',
        codmod: '',
        tauxAib: 0,
        fraisTransport: 0,
        cleRepartition: 0,
        dateRepartition: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        etatCmde: 'EN COURS',
        observation: '',

        waitingEndMsg: '',
        isLoading: true,
        loadingValidation: false,
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        services.getRepartitions()
            .then(repartitions => {
                //console.log('repartitions', repartitions)
                this.setState({ repartitions })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!repartitions || !Array.isArray(repartitions)
                        || !repartitions.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Répartition en Cours actuellement !" })
                    }
                }, 500)
            })
        services.getAgences()
            .then(agences => {
                //console.log('agences', agences)
                this.setState({ agences })
            })

        services.getStocksAgence()
            .then(articles => {
                //console.log('articles', articles)
                this.setState({ articles })
            })
    }
    handleAddOpen = () => {
        this.setState({
            qtEntree: 0,
            qteStock: 0,
            qteStockEnCoursValidation: 0,
            codAgence: '',
            observation: '',
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
            loading: false,
        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codCat: '',

            libCat: '',

        })
    }

    handleAddRepartition = () => {
        const {
            //codeEntre,
            //dateRepartition,
            // refFactFour,
            observation,
            codAgence,

            listeArticlesSelected,

        } = this.state


        if (codAgence === '') {
            message.warning('Veuillez sélectionner une Agence', 5)
            return;
        }

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }

        /*const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat((cur.puEntree * cur.qtEntree * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.puEntree * cur.qtEntree)
            }, 0) : 0

        const netaPayer = parseFloat(netHT) + parseFloat(totalTva) /*+ parseFloat(fraisTransport);*/
        this.setState({ loading: true })

        let req = {
            //codeEntre,
            dateRepartition: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            observation,
            codAgence,

            //montantAp: netaPayer,
            //netHT,
            //netaPayer,

            listeArticlesSelected,


        }


        ////console.log('REQ ADD APPROVISIONNEMENT', req)
        services.addRepartition(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    dateRepartition: '',
                    observation: '',
                    codAgence: '',
                    articlesCodPro: [],
                    listeArticlesSelected: [],
                    loading: false,

                    isAddModalVisible: !this.state.isAddModalVisible,

                })
                message.success('Répartition ajouter avec succès', 10)

                services.getRepartitions()
                    .then(repartitions => {
                        this.setState({ repartitions: repartitions, waitingEndMsg: '' })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10);
                this.setState({ loading: false })

            });
    }

    handleUpdateRepartition = () => {
        const {
            repartitionId,
            dateRepartition,
            observation,
            codAgence,

            listeArticlesSelected,

        } = this.state


        if (codAgence === '') {
            message.warning('Veuillez sélectionner une Agence', 5)
            return;
        }

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }


        let req = {
            repartitionId,
            dateRepartition,
            observation,
            codAgence,

            listeArticlesSelected,
        }

        services.updateRepartition(repartitionId, req)
            .then(result => {
                //console.log(result)
                this.setState({
                    repartitionId: '',
                    dateRepartition: '',
                    observation: '',
                    codAgence: '',
                    articlesCodPro: [],
                    listeArticlesSelected: [],
                    isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Répartition modifier avec succès', 10)

                services.getRepartitions()
                    .then(repartitions => {
                        this.setState({ repartitions })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
            });
    }

    handleDelete = (row) => {
        this.setState({
            repartitionId: row.id,
        })

    }

    handleDeleteApprovisionnement = () => {

        const { repartitionId } = this.state

        services.deleteRepartition(repartitionId)
            .then(del => {
                ////console.log('delete user', del)
                message.success('Répartition supprimer avec succès', 10)
                services.getRepartitions()
                    .then(repartitions => {
                        this.setState({ repartitions })

                        setTimeout(() => {

                            // //console.log("real articles", factures)
                            if (!repartitions || !Array.isArray(repartitions)
                                || !repartitions.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Répartition en Cours actuellement !" })
                            }
                        }, 500)
                    })

            })
            .catch((err) => {
                message.error(err, 10)
            });

    }

    handleValider = (row) => {
        this.setState({
            repartitionId: row.id,
            isValidationValidation: !this.state.isValidationValidation
        })

    }
    handleCloseValidation = () => {
        this.setState({ isValidationValidation: !this.state.isValidationValidation, codeEntre: '' })
    }

    handleValiderRepartition = () => {

        const { repartitionId } = this.state
        this.setState({ loadingValidation: true, loading: true })


        services.validerRepartition(repartitionId)
            .then(del => {
                ////console.log('delete user', del)
                message.success('Répartition Valider avec succès', 10)
                this.setState({
                    isValidationValidation: !this.state.isValidationValidation,
                    loading: false,
                })
                services.getRepartitions()
                    .then(repartitions => {
                        this.setState({ repartitions })
                        setTimeout(() => {

                            // //console.log("real articles", factures)
                            if (!repartitions || !Array.isArray(repartitions)
                                || !repartitions.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Répartition en Cours actuellement !" })
                            }
                        }, 500)
                    })

            })
            .catch(() => {
                message.error('Une erreur est survenue lors de la validation, Veuillez réessayer plus tard', 10)
                this.setState({loading: false})
            });

    }

    handleUpdateOpen = (row) => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            repartitionId: row.id,
            dateRepartition: row.dateRepartition,
            observation: row.observation,
            codAgence: row.codAgence,
            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        qtEntree: details.qtEntree,
                        qteStock: details && details.stock ? details.stock.qteStock : '',
                        qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })

    }

    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
            loading: false,


        })
    }


    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            dateRepartition: row.dateRepartition,
            observation: row.observation,
            codAgence: row.codAgence,
            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        qtEntree: details.qtEntree,
                        qteStock: details && details.stock ? details.stock.qteStock : '',
                        qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
        });
    }


    //agences
    handleCancelAddAgence = () => {
        this.setState({
            isAddAgenceModalVisible: !this.state.isAddAgenceModalVisible,
            codAgence: '',
            codificationAgence: '',
            nomAgence: '',
            adrAgence: '',
            telAgence: '',
            nomGerant: '',
            telGerant: '',
            observation: '',
        })
    }


    handleAddAgence = () => {
        const {
            //codAgence,
            codificationAgence,
            nomAgence,
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,
        } = this.state


        if (nomAgence === '') {
            message.warning('Veuillez préciser le nom de l\'Agence', 5)
            return;
        }
        /*if (codAgence === '') {
          message.warning('Veuillez sélectionner une Agence', 5)
          return;
        }*/

        let req = {
            //codAgence,
            codificationAgence,
            nomAgence: nomAgence.toUpperCase(),
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,
            //raisonSociale: raisonSociale.toUpperCase(),

        }
        services.addAgence(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    codAgence: '',
                    codificationAgence: '',
                    nomAgence: '',
                    adrAgence: '',
                    telAgence: '',
                    nomGerant: '',
                    telGerant: '',
                    observation: '',
                    isAddAgenceModalVisible: !this.state.isAddAgenceModalVisible,

                })
                message.success('Agences ajouter avec succès', 10)

                services.getAgences()
                    .then(agences => {
                        this.setState({ agences })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
            });
    }

    //FIN FOURNISSEUR


    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }


    openListArticleModal = () => {
        services.getStocksAgence()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }

    openListArticleModalUpdate = () => {
        services.getStocksAgence()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
        this.setState({ isListArticleVisibleUpdate: !this.state.isListArticleVisibleUpdate })
    }


    onRechercheArticleChange = (e) => {
        services.getStocksAgence(e.target.value.toUpperCase())
            .then(articles => {
                //console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
    }

    handleArticleSelected = () => {

        const {
            qteStock,
            qtEntree,
            qteStockEnCoursValidation,
            //puEntree,
            //codTva,
            article,
            articles,
        } = this.state

        if (qtEntree === '') {
            message.warning('Veuillez préciser la Quantité à Répartir', 6)
            return;
        }
        if (qtEntree > (parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation))) {
            message.warning('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord', 6)
            return;
        }

        const articleCod = article.codProd

        /*let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") &&
            0
            ||
            (this.state.codTva === "B" || this.state.codTva === "D") &&
            18;
*/

        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    qtEntree: qtEntree,
                    qteStock: qteStock,
                    qteStockEnCoursValidation: qteStockEnCoursValidation,
                    //puEntree: puEntree,
                    //codTva: codTva,
                    //tauxTva: tauxTva,
                    codProd: article.codProd,
                    designation: article.article ? article.article.designation : ''

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd) && x.codProd !== articleCod),
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            isListArticleVisible: !this.state.isListArticleVisible,
            qtEntree: '',
        }))
        // //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }

    handleArticleSelectedUpdate = () => {

        const {
            qteStock,
            qtEntree,
            qteStockEnCoursValidation,
            //puEntree,
            //codTva,
            article,
            articles,
        } = this.state

        if (qtEntree === '') {
            message.warning('Veuillez préciser la Quantité à Répartir', 6)
            return;
        }
        if (qtEntree > (parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation))) {
            message.warning('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord', 6)
            return;
        }

        const articleCod = article.codProd

        /*let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") &&
            0
            ||
            (this.state.codTva === "B" || this.state.codTva === "D") &&
            18;
*/

        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    qtEntree: qtEntree,
                    qteStock: qteStock,
                    qteStockEnCoursValidation: qteStockEnCoursValidation,
                    //puEntree: puEntree,
                    //codTva: codTva,
                    //tauxTva: tauxTva,
                    codProd: article.codProd,
                    designation: article.article ? article.article.designation : ''

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd) && x.codProd !== articleCod),
            isDetailsArticleVisibleUpdate: !this.state.isDetailsArticleVisibleUpdate,
            isListArticleVisibleUpdate: !this.state.isListArticleVisibleUpdate,
            qtEntree: '',
        }))
        // //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }


    handleDeleteArticlesSelected = (article) => {

        const { listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, listeArticlesSelected)

        this.setState({
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    openDetailArticleModal = (article) => {
        ////console.log('article', article)
        this.setState({
            article: article,
            qteStock: article.qteStock,
            qteStockEnCoursValidation: article.qteStockEnCoursValidation,
            designation: article ? article.article.designation : '',
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            //qtEntree: '',
            //puEntree: '',
            //codTva: '',
        })
    }

    openDetailArticleModalUpdate = (article) => {
        ////console.log('article', article)
        this.setState({
            article: article,
            qteStock: article.qteStock,
            qteStockEnCoursValidation: article.qteStockEnCoursValidation,
            designation: article ? article.article.designation : '',
            isDetailsArticleVisibleUpdate: !this.state.isDetailsArticleVisibleUpdate,
            //qtEntree: '',
            //puEntree: '',
            //codTva: '',
        })
    }

    closeDetailArticleModal = () => {
        this.setState({ isDetailsArticleVisible: false })
    }

    closeDetailArticleModalUpdate = () => {
        this.setState({ isDetailsArticleVisibleUpdate: false })
    }



    handleChangeEtatCmde = (value) => {
        this.setState({ etatCmde: value })
        this.setState({ isLoading: true, waitingEndMsg: '' })

        services.getRepartitions(value)
            .then(repartitions => {
                // //console.log('repartitions', repartitions)
                this.setState({ repartitions })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!repartitions || !Array.isArray(repartitions)
                        || !repartitions.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Répartition en Cours actuellement !" })
                    }
                }, 500)
            })
    }


    render() {
        const {
            isModalVisible,
            loading,

            //codeEntre,
           // dateRepartition,
            //refFactFour,
            observation,
            codAgence,
           // modRegl,
            // montantAp,
            //codmod,



            etatCmde,
            //numPoint,
           // valider,
           // datValid,

           // fraisTransport,
            agences,
            listeArticlesSelected,
            articles,

          //  codCat,

         //   libCat,

            repartitions,
            qteStockEnCoursValidation,



            isAddAgenceModalVisible,
            isAddModalVisible,
            isUpdateModalVisible,
            isListArticleVisible,
            isListArticleVisibleUpdate,
            isDetailsArticleVisible,
            isDetailsArticleVisibleUpdate,
            isValidationValidation,

            qtEntree,

            codificationAgence,
            nomAgence,
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            //observation,

            qteStock,

            //formLayout,

            //tauxAib,
           // montantAib,

            designation,
            isLoading,
            waitingEndMsg,
            //loadingValidation,

        } = this.state

        // //console.log('listeArticlesSelected', listeArticlesSelected)

        const titleDetailsArticle = `Complèter les détails pour l'Article ${designation}`

        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.qtEntree)
            }, 0) : 0


        /*const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat((cur.puEntree * cur.qtEntree * cur.tauxTva) / 100)
            }, 0) : 0*/

        /*const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.puEntree * cur.qtEntree)
            }, 0) : 0*/

        //const netaPayer = parseFloat(netHT) + parseFloat(totalTva) /*+ parseFloat(fraisTransport)*/;

        //const montantAp = netaPayer;

        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        /*const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;*/


        const columnsRepartitions = [{
            dataField: 'id',
            text: ' ID'
        },
        {
            dataField: 'etatCmde',
            text: ' Etat'
        },
        {
            dataField: 'dateRepartition',
            text: 'Date'
        },
        {
            dataField: 'agences.nomAgence',
            text: 'Agences'
        },
        {
            dataField: 'observation',
            text: 'Observation'
        },

        {
            dataField: 'userInfo',
            text: 'Agents',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            row.userInfo ? `${row.userInfo.prenomAgent} ${row.userInfo.nomAgent}` : ''
                        }
                    </div>
                );
            },
        },

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'VALIDER' &&
                            <Button
                                title="Visualiser"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                            <Button
                                hidden={use_logiciel === 'NO' ? false : true}
                                title="Modifier"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                            <button
                                hidden={use_logiciel === 'NO' ? false : true}
                                title="Valider"
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                className="btn bg-success text-light rounded btn-lg"
                                //className="gx-btn-block gx-btn-yellow"
                                size="sm"
                                onClick={() => this.handleValider(row)}
                            >
                                <i className="icon icon-check-circle-o" />
                            </button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Répartition??" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    title="Suppprimer"
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>
                        }

                    </div>
                );
            },

        }

        ];


        const columnsRepartitionsPhone = [

            {
                dataField: 'etatCmde',
                text: ' Etat'
            },
            {
                dataField: 'dateRepartition',
                text: 'Date'
            },
            {
                dataField: 'agences.nomAgence',
                text: 'Agences'
            },



            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleUpdateOpen(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    title="Valider"
                                    //type="danger"
                                    //fullWidth
                                    variant="contained"
                                    //color="primary"
                                    size="sm"
                                    className="btn bg-success text-light rounded btn-lg"
                                    //className="gx-btn-block gx-btn-yellow"
                                    onClick={() => this.handleValider(row)}
                                >
                                    <i className="icon icon-check-circle-o" />
                                </button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];


        return (
            <div>
                {(currentUser && /*currentUser.slug === "ADMIN_ROLE" &&*/ currentUser.agence.enCours === true) ?
                <Card title="Liste des repartitions">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={repartitions}
                            columns={columnsRepartitions}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '170%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'baseline', }}>
                                                <FormGroup>
                                                    <Select

                                                        value={etatCmde}
                                                        showSearch
                                                        //style={{width: 200}}
                                                        style={{ width: '120%' }}
                                                        placeholder="Filtrer par statut"
                                                        optionFilterProp="children"
                                                        onChange={this.handleChangeEtatCmde}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >

                                                        <Option value="EN COURS">EN COURS</Option>
                                                        <Option value="VALIDER">VALIDER</Option>

                                                    </Select>
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Status</span></h6>
                                                </FormGroup>




                                                {use_logiciel === 'NO' &&  //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                }
                                            </Col>


                                        </Row>

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            repartitions &&
                                                Array.isArray(repartitions) &&
                                                repartitions.length > 0 ?

                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={repartitions}
                            columns={columnsRepartitionsPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', display: 'flex' }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <FormGroup>
                                                    <Select
                                                        //style={{ maxWidth: 650, width: '90%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                        value={etatCmde}
                                                        showSearch
                                                        //style={{width: 200}}
                                                        style={{ width: '90%' }}
                                                        placeholder="Filtrer par statut"
                                                        optionFilterProp="children"
                                                        onChange={this.handleChangeEtatCmde}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >

                                                        <Option value="EN COURS">EN COURS</Option>
                                                        <Option value="VALIDER">VALIDER</Option>

                                                    </Select>
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Status</span></h6>
                                                </FormGroup>

                                            </Col>
                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&

                                                <Col className="mt-2" md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                                            }

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                            hover
                                            condensed
                                            headerWrapperClasses="foo"
                                            noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>
                :
                <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                </Col>
            </Row>
        }



                <Modal
                    width={600}
                    visible={isListArticleVisible}
                    title="Liste Produits"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModal}
                    footer={[
                        /* <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateRepartition}>
                             Enrégistrer
                         </Button>,*/
                        <Button key="back" type="danger" onClick={this.openListArticleModal}>
                            Fermer
                        </Button>,

                    ]}
                >
                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            <th className="text-center">Produits</th>
                                            <th className="text-center">Stock Actuel</th>
                                            <th className="text-center">Stock En Validation</th>
                                            <th className="text-center">Stock Restant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            articles && articles.length ?
                                                articles.map((article, k) => {
                                                    return article.article && article.article.designation &&
                                                        <tr key={k} id={k} onClick={(e) => this.openDetailArticleModal(article)} style={{ cursor: "pointer" }}>
                                                            <td className="text-center">{article.article ? article.article.designation : ''}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            <td className="text-center">{article.qteStockEnCoursValidation}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                        </tr>
                                                }) : null
                                        }

                                    </tbody>
                                </Table>

                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>

                <Modal
                    width={600}
                    visible={isListArticleVisibleUpdate}
                    title="Liste Produits"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModalUpdate}
                    footer={[
                        /* <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateRepartition}>
                             Enrégistrer
                         </Button>,*/
                        <Button key="back" type="danger" onClick={this.openListArticleModalUpdate}>
                            Fermer
                        </Button>,

                    ]}
                >
                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            <th className="text-center">Produits</th>
                                            <th className="text-center">Stock Actuel</th>
                                            <th className="text-center">Stock En Validation</th>
                                            <th className="text-center">Stock Restant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            articles && articles.length ?
                                                articles.map((article, k) => {
                                                    return article.article && article.article.designation &&
                                                        <tr key={k} id={k} onClick={(e) => this.openDetailArticleModalUpdate(article)} style={{ cursor: "pointer" }}>
                                                            <td className="text-center">{article.article ? article.article.designation : ''}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            <td className="text-center">{article.qteStockEnCoursValidation}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                        </tr>
                                                }) : null
                                        }

                                    </tbody>
                                </Table>

                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>


                <Modal
                    width={800}
                    visible={isAddModalVisible}
                    title="Effectuer une répartition"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddRepartition}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <Row form>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                       </Row>*/}

                        <Row>
                            <Col md={6}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={6} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté à Répartir</th>
                                            <th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qtEntree) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                            <Button className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        {/*<Row className="mt-3">
                            <Col md={12}>
                                <Card title="Totaux et Taxes" className="gx-card">
                                    <FormItem label="Net HT"  {...formItemLayout}>

                                        <Input disabled type="number" name="netHT" id="netHT" value={netHT} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="Net à Payer"  {...formItemLayout}>
                                        <Input disabled type="number" name="netaPayer" id="netaPayer" value={netaPayer} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>
                            </Col>
                                    </Row>*/}
                    </Form>
                </Modal>

                <Modal
                    width={900}
                    visible={isUpdateModalVisible}
                    title="Modifier la Répartiton"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateRepartition}>
                            Modifier
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <Row form>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                       </Row>*/}

                        <Row>
                            <Col md={8}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModalUpdate}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté à Répartir</th>
                                            {/*<th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>*/}
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            {/*<td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation) + parseFloat(article.qtEntree)}</td>

                                                    <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>*/}

                                                            <Button className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Répartition"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            disabled
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button disabled onClick={this.handleCancelAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input disabled type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <Row form>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                       </Row>*/}

                        <Row>
                            <Col md={8}>
                                <button disabled className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté à Répartir</th>
                                            <th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation) + parseFloat(article.qtEntree)}</td>

                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                            <Button disabled className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isAddAgenceModalVisible}
                    title="Ajouter une Agence"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAddAgence}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddAgence}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAddAgence}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="codificationAgence">Codification</Label>
                                    <Input type="text" name="codificationAgence" value={codificationAgence} id="codificationAgence" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="nomAgence">Nom Agence</Label>
                                    <Input type="text" name="nomAgence" value={nomAgence} onChange={this.handleChange} id="nomAgence" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adrAgence">Adresse</Label>
                                    <Input type="text" name="adrAgence" id="adrAgence" value={adrAgence} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telAgence">Téléphone</Label>
                                    <Input type="number" name="telAgence" value={telAgence} id="telAgence" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup >
                                    <Label for="nomGerant">Nom Gérant</Label>
                                    <Input type="text" name="nomGerant" value={nomGerant} id="nomGerant" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telGerant">Téléphone Gérant</Label>
                                    <Input type="number" name="telGerant" value={telGerant} id="telGerant" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="observation">Observation</Label>
                            <Input type="text" name="observation" value={observation} id="observation" onChange={this.handleChange} placeholder="" />
                        </FormGroup>


                    </Form>
                </Modal>



                <Modal
                    width={500}
                    visible={isDetailsArticleVisible}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModal}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleArticleSelected}>
                            Ajouter
                        </Button>,
                        <Button key="back" type="danger" onClick={this.closeDetailArticleModal}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="qtEntree">Qté à Répartir</Label>
                                    <Input type="number" name="qtEntree" id="qtEntree" value={qtEntree} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qteStock">Stok Potentiel Restant</Label>
                                    <Input disabled type="number" name="qteStock" value={parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation)} onChange={this.handleChange} id="qteStock" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={500}
                    visible={isDetailsArticleVisibleUpdate}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModalUpdate}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleArticleSelectedUpdate}>
                            Ajouter
                        </Button>,
                        <Button key="back" type="danger" onClick={this.closeDetailArticleModalUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="qtEntree">Qté à Répartir</Label>
                                    <Input type="number" name="qtEntree" id="qtEntree" value={qtEntree} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qteStock">Stok Potentiel Restant</Label>
                                    <Input disabled type="number" name="qteStock" value={parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation)} onChange={this.handleChange} id="qteStock" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={400}
                    visible={isValidationValidation}
                    title="Validation Répartition"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseValidation}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onDoubleClick={this.handleValiderRepartition}>
                            Valider
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCloseValidation}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <h5>
                        Voulez vous valider cette Répartition...?
                    </h5>
                    <p style={{ color: "red" }}>Si OUI, veuillez double cliquer sur le boutton VALIDER</p>

                </Modal>

            </div>
        )
    }
}


export default Repartitions;
