import React from "react";
import { Card, Form, Input, Button, Modal, Select, message, Popconfirm, } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, ListGroupItem, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { services } from '../../../services/services'
import { use_logiciel } from '../../../env'

import { numberWithCommas } from '../../../helpers/index';
import { authenticationService } from "../../../services/authentication-service";




//const confirm = Modal.confirm;

const FormItem = Form.Item;

const moment = require('moment')

const currentUser = authenticationService.currentUserValue


class Approvisionnements extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isListArticleVisibleUpdate: false,

        isDetailsArticleVisible: false,
        isDetailsArticleVisibleUpdate: false,
        isValidationApprovisionnement: false,
        approvisionnements: [],
        listeArticlesSelected: [],
        articles: [],
        articlesCodPro: [],
        formLayout: 'horizontal',

        usuel: "1",
        codTyp: "1",
        libCat: '',
        codeCat: '',
        emailFour: '',
        refFactFour: '',
        codFour: '',
        modRegl: '',
        codmod: '',
        tauxAib: 0,
        fraisTransport: 0,
        cleRepartition: 0,
        dateEntree: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        etatCmde: 'EN COURS',
        observation: '',

        waitingEndMsg: '',
        isLoading: true,
        loadingValidation: false,
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        services.getApprovisionnements()
            .then(approvisionnements => {
                //console.log('approvisionnements', approvisionnements)
                this.setState({ approvisionnements })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!approvisionnements || !Array.isArray(approvisionnements)
                        || !approvisionnements.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Approvisionnement en Cours actuellement !" })
                    }
                }, 500)
            })
        services.getFournisseurs()
            .then(fournisseurs => {
                //console.log('fournisseurs', fournisseurs)
                this.setState({ fournisseurs })
            })

        services.getArticles()
            .then(articles => {
                //console.log('articles', articles)
                this.setState({ articles })
            })
    }
    handleAddOpen = () => {
        this.setState({
            codeEntre: '',
            //dateEntree: '',
            refFactFour: '',
            observation: '',
            codFour: '',
            modRegl: '',
            montantAp: 0,
            codmod: '',

            tauxAib: 0,
            montantAib: 0,

            fraisTransport: 0,

            totalTva: 0,
            netHT: 0,
            netaPayer: 0,
            loading: false,
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codCat: '',

            libCat: '',

        })
    }

    handleAddAprovisionnement = () => {
        const {
            codeEntre,
            //dateEntree,
            refFactFour,
            observation,
            codFour,
            //modRegl,
            //montantAp,
            //codmod,

            //tauxAib,
            //montantAib,

            //fraisTransport,

            //totalTva,
            //netHT,
            //netaPayer,

            listeArticlesSelected,

        } = this.state



        if (refFactFour === '') {
            message.warning('Veuillez préciser le numéro de la Facture', 5)
            return;
        }
        if (codFour === '') {
            message.warning('Veuillez sélectionner un Fournisseur', 5)
            return;
        }
        /*if (modRegl === '') {
            message.warning('Veuillez préciser le Type de Règlement', 5)
            return;
        }
        if (codmod === '') {
            message.warning('Veuillez préciser le Mode de Règlement', 5)
            return;
        }*/

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }

        /*const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat((cur.puEntree * cur.qtEntree * cur.tauxTva) / 100)
            }, 0) : 0
*/
        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.puEntree * cur.qtEntree)
            }, 0) : 0

        const netaPayer = parseFloat(netHT) /*+ parseFloat(totalTva) /*+ parseFloat(fraisTransport)*/;

        this.setState({ loading: true })
        //this.setState({ loadingValidation: true })


        let req = {
            codeEntre,
            dateEntree: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            refFactFour,
            observation,
            codFour,
            //modRegl,
            montantAp: netaPayer,
            //codmod,

            //tauxAib,
            //montantAib,

            //fraisTransport,

            // totalTva,
            //netHT,
            //netaPayer,

            listeArticlesSelected,


        }


        ////console.log('REQ ADD APPROVISIONNEMENT', req)
        services.addApprovisionnement(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    codeEntre: '',
                    dateEntree: '',
                    refFactFour: '',
                    observation: '',
                    codFour: '',
                    modRegl: '',
                    montantAp: '',
                    codmod: '',

                    tauxAib: '',
                    montantAib: '',

                    fraisTransport: '',

                    totalTva: '',
                    netHT: '',
                    netaPayer: '',
                    articlesCodPro: [],
                    listeArticlesSelected: [],
                    loading: false,


                    isAddModalVisible: !this.state.isAddModalVisible,

                })
                message.success('Approvisionnement ajouter avec succès', 10)

                services.getApprovisionnements()
                    .then(approvisionnements => {
                        this.setState({ approvisionnements, waitingEndMsg: '', })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10);
                this.setState({ loading: false })
            });
    }

    handleUpdateApprovisionnement = () => {
        const {
            codeEntre,
            dateEntree,
            refFactFour,
            observation,
            codFour,
            //modRegl,
            //montantAp,
            //codmod,

            //tauxAib,
            //montantAib,

            //fraisTransport,

            //totalTva,
            //netHT,
            //netaPayer,

            listeArticlesSelected,

        } = this.state


        if (refFactFour === '') {
            message.warning('Veuillez préciser le numéro de la Facture', 5)
            return;
        }
        if (codFour === '') {
            message.warning('Veuillez sélectionner un Fournisseur', 5)
            return;
        }
        /*if (modRegl === '') {
            message.warning('Veuillez préciser le Type de Règlement', 5)
            return;
        }
        if (codmod === '') {
            message.warning('Veuillez préciser le Mode de Règlement', 5)
            return;
        }*/

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }

        /*const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat((cur.puEntree * cur.qtEntree * cur.tauxTva) / 100)
            }, 0) : 0
*/
        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.puEntree * cur.qtEntree)
            }, 0) : 0

        const netaPayer = parseFloat(netHT) /*+ parseFloat(totalTva) /*+ parseFloat(fraisTransport)*/;


        let req = {
            codeEntre,
            dateEntree,
            refFactFour,
            observation,
            codFour,
            //modRegl,
            montantAp: netaPayer,
            //codmod,

            //tauxAib,
            //montantAib,

            //fraisTransport,

            //totalTva,
            //netHT,
            netaPayer,

            listeArticlesSelected,


        }
        services.updateApprovisionnement(codeEntre, req)
            .then(result => {
                //console.log(result)
                this.setState({
                    codeEntre: '',
                    dateEntree: '',
                    refFactFour: '',
                    observation: '',
                    codFour: '',
                    modRegl: '',
                    montantAp: '',
                    codmod: '',

                    tauxAib: '',
                    montantAib: '',

                    fraisTransport: '',

                    totalTva: '',
                    netHT: '',
                    netaPayer: '',
                    articlesCodPro: [],
                    listeArticlesSelected: [],
                    isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Approvisionnement modifier avec succès', 10)

                services.getApprovisionnements()
                    .then(approvisionnements => {
                        this.setState({ approvisionnements })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
            });
    }

    handleDelete = (row) => {
        this.setState({
            codeEntre: row.codeEntre,
        })

    }

    handleDeleteApprovisionnement = () => {

        const { codeEntre } = this.state

        services.deleteApprovisionnement(codeEntre)
            .then(del => {
                ////console.log('delete user', del)
                message.success('Approvisionnement supprimer avec succès', 10)
                services.getApprovisionnements()
                    .then(approvisionnements => {
                        this.setState({ approvisionnements })
                        setTimeout(() => {

                            // //console.log("real articles", factures)
                            if (!approvisionnements || !Array.isArray(approvisionnements)
                                || !approvisionnements.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Approvisionnement en Cours actuellement !" })
                            }
                        }, 1000)
                    })

            })
            .catch((err) => {
                message.error(err, 10)
            });

    }

    handleValider = (row) => {
        this.setState({
            codeEntre: row.codeEntre,
            isValidationApprovisionnement: !this.state.isValidationApprovisionnement
        })

    }
    handleCloseValidation = () => {
        this.setState({ isValidationApprovisionnement: !this.state.isValidationApprovisionnement, codeEntre: '' })
    }

    handleValiderApprovisionnement = () => {

        const { codeEntre } = this.state
        this.setState({loading: true})
        services.validerApprovisionnement(codeEntre)
            .then(del => {
                ////console.log('delete user', del)
                message.success('Approvisionnement Valider avec succès', 10)
                this.setState({
                    isValidationApprovisionnement: !this.state.isValidationApprovisionnement,
                    loading: false
                })
                services.getApprovisionnements()
                    .then(approvisionnements => {
                        this.setState({ approvisionnements })
                        setTimeout(() => {

                            // //console.log("real articles", factures)
                            if (!approvisionnements || !Array.isArray(approvisionnements)
                                || !approvisionnements.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Approvisionnement en Cours actuellement !" })
                            }
                        }, 1000)
                    })

            })
            .catch(() => {
                message.error('Une erreur est survenue lors de la validation, Veuillez réessayer plus tard', 10)
                this.setState({loading: false})
            });

    }

    handleUpdateOpen = (row) => {
        //console.log('ROW', row)
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,

            codCat: row.codCat,
            codeEntre: row.codeEntre,
            dateEntree: row.dateEntree, //moment(new Date(row.dateEntree), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            refFactFour: row.refFactFour,
            observation: row.observation,
            //codFour: `${row.codFour}`,
            codFour: row.codFour,
            modRegl: row.modRegl,
            montantAp: row.montantAp,
            codmod: row.codmod,

            tauxAib: row.tauxAib,
            montantAib: row.montantAib,

            //fraisTransport: 0,//row.fraisTransport,

            totalTva: row.totalTva,
            netHT: row.netHT,
            netaPayer: row.netaPayer,

            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        codProd: details && details.article ? details.article.codPro : '',
                        qtEntree: parseFloat(details.qtEntree),
                        puEntree: parseFloat(details.puEntree),
                        codTva: details.codTva,
                        tauxTva: details.tauxTva,
                        //codProd: details.codPro,
                        designation: details && details.article ? details.article.designation : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })

    }
    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            codeEntre: '',
            dateEntree: '',
            refFactFour: '',
            observation: '',
            codFour: '',
            modRegl: '',
            montantAp: '',
            codmod: '',

            tauxAib: '',
            montantAib: '',

            fraisTransport: '',

            totalTva: '',
            netHT: '',
            netaPayer: '',

            listeArticlesSelected: [],

        })
    }


    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            codCat: row.codCat,
            codeEntre: row.codeEntre,
            dateEntree: row.dateEntree, //moment(new Date(row.dateEntree), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            datValid: row.datValid,// moment(new Date(row.datValid), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            refFactFour: row.refFactFour,
            observation: row.observation,
            //codFour: `${row.codFour}`,
            codFour: row.codFour,
            modRegl: row.modRegl,
            montantAp: row.montantAp,
            codmod: row.codmod,

            tauxAib: row.tauxAib,
            montantAib: row.montantAib,

            //fraisTransport: 0,//row.fraisTransport,

            totalTva: row.totalTva,
            netHT: row.netHT,
            netaPayer: row.netaPayer,

            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        codProd: details && details.article ? details.article.codPro : '',
                        qtEntree: parseFloat(details.qtEntree),
                        puEntree: parseFloat(details.puEntree),
                        codTva: details.codTva,
                        tauxTva: details.tauxTva,
                        //codProd: details.codPro,
                        designation: details && details.article ? details.article.designation : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.codPro ? details.codPro : ''
                }) : [],




        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            codeEntre: '',
            dateEntree: '',
            refFactFour: '',
            observation: '',
            codFour: '',
            modRegl: '',
            montantAp: '',
            codmod: '',

            tauxAib: '',
            montantAib: '',

            fraisTransport: '',

            totalTva: '',
            netHT: '',
            netaPayer: '',

            listeArticlesSelected: [],
        });
    }
    //FOURNISSEURS
    handleCancelAddFournisseur = () => {
        this.setState({
            isAddFournisseurModalVisible: !this.state.isAddFournisseurModalVisible,
            codFour: '',
            numIfu: '',
            raisonSociale: '',
            adrFour: '',
            contactFour: '',
            emailFour: '',
            siteWeb: '',
            observation: '',
        })
    }


    handleAddFournisseur = () => {
        const {
            codFour,
            numIfu,
            raisonSociale,
            adrFour,
            contactFour,
            emailFour,
            siteWeb,
            observation,
        } = this.state


        if (raisonSociale === '') {
            message.warning('Veuillez préciser la Raison sociale', 5)
            return;
        }
        /*if (codFour === '') {
            message.warning('Veuillez préciser le code Fournisseur', 5)
            return;
        }*/

        let req = {
            codFour,
            numIfu,
            raisonSociale: raisonSociale.toUpperCase(),
            adrFour,
            contactFour,
            emailFour,
            siteWeb,
            observation,
            //raisonSociale: raisonSociale.toUpperCase(),

        }
        services.addFournisseur(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    codFour: '',
                    numIfu: '',
                    raisonSociale: '',
                    adrFour: '',
                    contactFour: '',
                    emailFour: '',
                    siteWeb: '',
                    observation: '',
                    isAddFournisseurModalVisible: !this.state.isAddFournisseurModalVisible,

                })
                message.success('Fournisseurs ajouter avec succès', 10)

                services.getFournisseurs()
                    .then(fournisseurs => {
                        this.setState({ fournisseurs })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
            });
    }

    //FIN FOURNISSEUR

    handleChangeAib = (value) => {
        this.setState({ tauxAib: value })
    }

    handleChangeCategorie = (value) => {
        this.setState({ codFour: value })
    }

    handleChangeTypeReglement = (value) => {
        this.setState({ modRegl: value })
    }

    handleChangeModReglem = (value) => {
        this.setState({ codmod: value })
    }

    openListArticleModal = () => {
        services.getArticles()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro)),
                })
            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }

    openListArticleModalUpdate = () => {
        services.getArticles()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro)),
                })
            })
        this.setState({ isListArticleVisibleUpdate: !this.state.isListArticleVisibleUpdate })
    }

    onRechercheArticleChange = (e) => {
        ////console.log('recherche', e.target.value.toUpperCase())
        //const { codeUtilisateur } = this.state
        services.getArticles(e.target.value.toUpperCase())
            .then(articles => {
                //console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro)),
                })
            })
    }

    handleArticleSelected = () => {
        this.setState({ loading: true })
        const {
            qtEntree,
            puEntree,
            //codTva,
            article,
            articles,
        } = this.state

        if (puEntree === '') {
            message.warning('Veuillez préciser le Prix', 6)
            this.setState({ loading: false })
            return;
        }
        if (qtEntree === '') {
            message.warning('Veuillez préciser la Quantité', 6)
            this.setState({ loading: false })
            return;
        }

        /*if (codTva === '' || codTva === null || codTva === undefined) {
            message.warning('Veuillez sélectionner la Tva', 6)
            return;
        }*/


        const articleCod = article.codPro
        /*let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") &&
            0
            ||
            (this.state.codTva === "B" || this.state.codTva === "D") &&
            18;
            */
        //this.setState({loading: true})
        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    qtEntree: qtEntree,
                    puEntree: puEntree,
                    //codTva: codTva,
                    //tauxTva: tauxTva,
                    codProd: article.codPro,
                    designation: article.designation

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro) && x.codPro !== articleCod),
            isDetailsArticleVisible: false, //!this.state.isDetailsArticleVisible,
            isListArticleVisible: false,//!this.state.isListArticleVisible,
            loading: false,
        }))
        //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }


    handleArticleSelectedUpdate = () => {

        const {
            qtEntree,
            puEntree,
            //codTva,
            article,
            articles,
        } = this.state

        if (qtEntree === '') {
            message.warning('Veuillez préciser la Quantité', 6)
            return;
        }
        if (puEntree === '') {
            message.warning('Veuillez préciser le Prix', 6)
            return;
        }
        /*if (codTva === '' || codTva === null || codTva === undefined) {
            message.warning('Veuillez sélectionner la Tva', 6)
            return;
        }*/


        const articleCod = article.codPro
        /*let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") &&
            0
            ||
            (this.state.codTva === "B" || this.state.codTva === "D") &&
            18;
            */
        //this.setState({loading: true})
        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    qtEntree: qtEntree,
                    puEntree: puEntree,
                    //codTva: codTva,
                    //tauxTva: tauxTva,
                    codProd: article.codPro,
                    designation: article.designation

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codPro) && x.codPro !== articleCod),
            isDetailsArticleVisibleUpdate: false, //!this.state.isDetailsArticleVisible,
            isListArticleVisibleUpdate: false,//!this.state.isListArticleVisible,
            loading: false,
        }))
        //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }

    handleDeleteArticlesSelected = (article) => {

        const { listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, listeArticlesSelected)

        this.setState({
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    openDetailArticleModal = (article) => {
        this.setState({
            article: article,
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            designation: article.designation,
            qtEntree: '',
            puEntree: '',
            codTva: '',
        })
    }

    openDetailArticleModalUpdate = (article) => {
        this.setState({
            article: article,
            isDetailsArticleVisibleUpdate: !this.state.isDetailsArticleVisibleUpdate,
            designation: article.designation,
            qtEntree: '',
            puEntree: '',
            codTva: '',
        })
    }

    closeDetailArticleModal = () => {
        this.setState({ isDetailsArticleVisible: false })
    }

    closeDetailArticleModalUpdate = () => {
        this.setState({ isDetailsArticleVisibleUpdate: false })
    }

    handleChangeTvaVente = (value) => {
        this.setState({ codTva: value })
    }

    handleChangeEtatCmde = (value) => {
        this.setState({ etatCmde: value });
        this.setState({ isLoading: true, waitingEndMsg: '' })

        services.getApprovisionnements(value)
            .then(approvisionnements => {
                //console.log('approvisionnements', approvisionnements)
                this.setState({ approvisionnements })
                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!approvisionnements || !Array.isArray(approvisionnements)
                        || !approvisionnements.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Approvisionnement en Cours actuellement !" })
                    }
                }, 1000)
            })
    }


    render() {
        const {
            isModalVisible,
            loading,

            codeEntre,
            dateEntree,
            refFactFour,
            observation,
            codFour,
           // modRegl,
            // montantAp,
            //codmod,



            etatCmde,
           // numPoint,
           // valider,
            datValid,

            //fraisTransport,
            fournisseurs,
            listeArticlesSelected,
            articles,

           // codCat,

          //  libCat,

            approvisionnements,

            //FOURNISSEUR
            //codFour,
            numIfu,
            raisonSociale,
            adrFour,
            contactFour,
            emailFour,
            siteWeb,

            isAddFournisseurModalVisible,
            isAddModalVisible,
            isUpdateModalVisible,
            isListArticleVisible,
            isListArticleVisibleUpdate,
            isDetailsArticleVisible,
            isDetailsArticleVisibleUpdate,
            isValidationApprovisionnement,

            qtEntree,
            puEntree,

           // codTva,
            //tauxTva,

            //formLayout,

            //tauxAib,
          //  montantAib,

            designation,
            isLoading,
            waitingEndMsg,
            //loadingValidation,

        } = this.state

        ////console.log('listeArticlesSelected', listeArticlesSelected)

        const titleDetailsArticle = `Complèter les détails pour l'Article ${designation}`

        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.qtEntree)
            }, 0) : 0


        /*const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat((cur.puEntree * cur.qtEntree * cur.tauxTva) / 100)
            }, 0) : 0*/

        /*const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.puEntree * cur.qtEntree)
            }, 0) : 0*/

        //const netaPayer = parseFloat(netHT) + parseFloat(totalTva) /*+ parseFloat(fraisTransport)*/;

        const netaPayer = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.puEntree * cur.qtEntree)
            }, 0) : 0

        //const montantAp = netaPayer;

        //const cleRepartition = parseFloat(fraisTransport / nbresArticles);

        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        /*const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;*/


        const columnsApprovisionnements = [
            {
                dataField: 'etatCmde',
                text: ' Etat Livr...'
            },
            {
                dataField: 'codeEntre',
                text: 'N° cmde '
            },
            {
                dataField: 'refFactFour',
                text: ' Référence'
            },
            {
                dataField: 'dateEntree',
                text: 'Date',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                row.dateEntree /*moment(new Date(row.dateEntree), 'DD/MM/YYYY').format('DD/MM/YYYY')*/
                            }
                        </div>
                    );
                },
            },
            {
                dataField: 'fournisseur.raisonSociale',
                text: 'Fournisseur'
            },
            {
                dataField: 'userInfo',
                text: 'Agents',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                row.userInfo ? `${row.userInfo.prenomAgent} ${row.userInfo.nomAgent}` : ''
                            }
                        </div>
                    );
                },
            },
            {
                dataField: 'montantAp',
                text: 'Montant',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                numberWithCommas(parseFloat(row.montantAp))
                            }
                        </div>
                    );
                },
            },

            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleUpdateOpen(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    title="Valider"
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="btn bg-success text-light rounded btn-lg"
                                    //size="large"
                                    onClick={() => this.handleValider(row)}
                                >
                                    <i className="icon icon-check-circle-o" />
                                </button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="sm"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>
                    );
                },

            }

        ];


        const columnsApprovisionnementsPhone = [

            {
                dataField: 'etatCmde',
                text: ' Etat Livr...'
            },
            {
                dataField: 'codeEntre',
                text: 'N° cmde '
            },
            {
                dataField: 'refFactFour',
                text: ' Référence'
            },
            /*{
                dataField: 'dateEntree',
                text: 'Date'
            },*/
            {
                dataField: 'fournisseur.raisonSociale',
                text: 'Fournisseur'
            },


            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleUpdateOpen(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    title="Valider"
                                    //type="danger"
                                    //fullWidth
                                    variant="contained"
                                    //color="primary"
                                    size="sm"
                                    className="btn bg-success text-light rounded btn-lg"
                                    //className="gx-btn-block gx-btn-yellow"
                                    onClick={() => this.handleValider(row)}
                                >
                                    <i className="icon icon-check-circle-o" />
                                </button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];


        /* const columnsTableauxArticles = [
             /*{
                 dataField: 'id',
                 text: 'N°',
                 style: {
                     overflow: 'hidden'
                 },
                 headerAttrs: { width: 30 },
                 //hidden: (statutOption === 'Chef Service') && true
             },/
             {
                 dataField: 'codProd',
                 text: 'Code*',
                 style: {
                     overflow: 'hidden'
                 },
                 headerAttrs: { width: 75 },
                 headerStyle: {
                     //backgroundColor: '#808080',
                     //color: '#007FFF'
                 },
                 editable: false
             },
 
             {
                 dataField: 'designation',
                 text: 'Désignation',
                 style: {
                     overflow: 'hidden',
                 },
                 headerAttrs: { width: 300 },
                 editable: false,
                 headerStyle: {
                     //backgroundColor: '#808080',
                     //color: '#007FFF'
                 },
             },
 
             {
                 dataField: 'puEntree',
                 headerAlign: 'center',
                 text: 'PU Achat',
                 sort: true,
                 style: {
                     overflow: 'hidden',
                     textAlign: 'center'
                 },
                 headerAttrs: { width: 100 },
                 type: 'number',
                 validator: (newValue, row, column) => {
                     if (isNaN(newValue)) {
                         return {
                             valid: false,
                             message: 'Le prix doit être au format numérique'
                         };
                     }
                     /*if (newValue < 2000) {
                       return {
                         valid: false,
                         message: 'Price should bigger than 2000'
                       };
                     }/
                     return true;
                 },
             },
             {
                 dataField: 'qtEntree',
                 text: 'Quantité',
                 style: {
                     overflow: 'hidden',
 
                 },
                 headerAttrs: { width: 120 },
                 type: 'number',
                 validator: (newValue, row, column) => {
                     if (isNaN(newValue)) {
                         return {
                             valid: false,
                             message: 'La quantité doit être au format numérique'
                         };
                     }
 
                     return true;
                 },
 
             },
             {
                 dataField: '',
                 text: 'Montant',
                 style: {
                     overflow: 'hidden',
 
                 },
                 headerAttrs: { width: 70 },
                 editable: false,
                 formatter: (cell, row, rowIndex, extraData) => (
                     <div className="text-center">{row.puEntree * row.qtEntree}</div>
                 ),
             },
             {
                 dataField: 'etatAffecte',
                 text: 'TVA',
                 headerAttrs: { width: 70 },
                 editCellStyle: (cell, row, rowIndex, colIndex) => {
                     const backgroundColor = cell > 1 ? '#00BFFF' : '#00FFFF';
                     return { backgroundColor };
                 },
                 formatter: (cell, row, rowIndex, extraData) => (
                     <div className="text-center">{row.etatAffecte && row.etatAffecte == 1 ? 'Bon' : row.etatAffecte == 2 ? 'Passable' : row.etatAffecte == 3 ? 'Mauvais' : ''}</div>
                 ),
                 style: {
                     overflow: 'hidden',
                 },
                 editor: {
                     type: Type.SELECT,
                     options: [{
                         value: '1',
                         label: 'Bon'
                     }, {
                         value: '2',
                         label: 'Passable'
                     }, {
                         value: '3',
                         label: 'Mauvais'
                     },
                     ]
                 },
                 headerStyle: {
                     // backgroundColor: '#808080',
                     // color: '#007FFF'
                 }
 
 
             },
 
             {
                 dataField: '',
                 text: 'Clé de Répartition',
                 sort: true,
                 /* formatter: (cell, row, rowIndex, extraData) => (
                    <div>{row.numeroProFormat}</div>
                  ),/
 
                 headerAttrs: { width: 80 },
                 editable: false
             },
 
             /*
              {
                dataField: '',
                text: 'N° Moteur',
                sort: true,
                formatter: (cell, row, rowIndex, extraData) => {
                  //console.log('row.date', row.date)
                  return (
                    <div className="text-center">{row.date !== 'Invalid date' ? row.date : '-'}</div>
                  )
                },
                style: {
                  overflow: 'hidden',
                },
                headerAttrs: { width: 70 }
              },/
 
             {
                 dataField: 'designationArticle',
                 text: 'PU de Revient',
                 sort: true,
                 /*formatter: (cell, row, rowIndex, extraData) => (
                   <div className="text-center">{row.article ? row.article.codeNature : ''}</div>
                 ),/
                 style: {
                     overflow: 'hidden',
                 },
                 headerAttrs: { width: 70 },
                 editable: false
             },
             /* {
                dataField: 'actions',
                text: 'ACTIONS',
                headerAlign: 'center',
                isDummyField: true,
                formatter:  (cell, row, rowIndex, formatExtraData) => {
                  ////console.log('sortie v', row)
                  return (
                    < div className="d-flex justify-content-center align-item-center">
                     <acronym title="Visualiser ce Materiel"><Button className="m-1" onClick={() => this.handleToggleDetail(row)} variant="primary" color="success"><i style={{ color: 'white' }} className="fa fa-eye fa-lg"></i></Button> </acronym>
                      <acronym title="Modifier ce Materiel"><Button className="m-1" color="primary" onClick={() => this.handleToggle(row)} variant="primary"><i style={{ color: 'white' }} className="fa fa-edit fa-sm"></i></Button> </acronym>
                      
                    </div>
                  )},
                sort: false,
                headerAttrs: { width: 100 },
                attrs: { width: 50, className: "actions" }
              }/
 
 
         ];*/




        return (
            <div>
                {(currentUser && /*currentUser.slug === "ADMIN_ROLE" &&*/ currentUser.agence.enCours === true) ?
                    <Card title="Liste des approvisionnements">

                        <Media query="(min-width: 600px)" render={() =>
                        (
                            <ToolkitProvider
                                keyField="id"
                                data={approvisionnements}
                                columns={columnsApprovisionnements}

                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ maxWidth: 750, width: '170%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'baseline', }}>
                                                    <FormGroup >
                                                        <Select

                                                            value={etatCmde}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '140%' }}
                                                            placeholder="Filtrer par statut"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeEtatCmde}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >

                                                            <Option value="EN COURS">EN COURS</Option>
                                                            <Option value="VALIDER">VALIDER</Option>

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Status</span></h6>
                                                    </FormGroup>





                                                    {use_logiciel === 'NO' &&
                                                        <button style={{ marginLeft: 3 }}
                                                            className="btn bg-primary text-light rounded"
                                                            onClick={this.handleAddOpen}>
                                                            NOUVEAU <i className="icon icon-add" />
                                                        </button>
                                                    }
                                                </Col>


                                            </Row>

                                            {
                                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                            }
                                            {
                                                approvisionnements &&
                                                    Array.isArray(approvisionnements) &&
                                                    approvisionnements.length > 0 ?

                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        //bootstrap4
                                                        wrapperClasses="table-responsive"
                                                        //selectRow={ selectRow }
                                                        //defaultSorted={defaultSorted}
                                                        pagination={paginationFactory()}
                                                        defaultSorted={defaultSorted}
                                                        striped
                                                        hover
                                                        condensed
                                                        headerWrapperClasses="foo"
                                                        noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                    //filter={filterFactory()}
                                                    />
                                                    :
                                                    isLoading ?
                                                        <div style={{ justifyContent: 'center' }}>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                <Col style={{ justifyContent: 'center' }}>
                                                                    <Spinner type="grow" color="primary" />
                                                                    <Spinner type="grow" color="secondary" />
                                                                    <Spinner type="grow" color="success" />
                                                                    <Spinner type="grow" color="danger" />
                                                                    <Spinner type="grow" color="warning" />
                                                                    <Spinner type="grow" color="info" />
                                                                    <Spinner type="grow" color="light" />
                                                                    <Spinner type="grow" color="dark" />
                                                                </Col>
                                                            </Row> <br />
                                                        </div>
                                                        : null
                                            }

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (

                            <ToolkitProvider
                                keyField="id"
                                data={approvisionnements}
                                columns={columnsApprovisionnementsPhone}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ maxWidth: 450, width: '125%', display: 'flex' }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>
                                                    <FormGroup>
                                                        <Select
                                                            //style={{ maxWidth: 650, width: '90%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                            value={etatCmde}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '90%' }}
                                                            placeholder="Filtrer par statut"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeEtatCmde}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >

                                                            <Option value="EN COURS">EN COURS</Option>
                                                            <Option value="VALIDER">VALIDER</Option>

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Status</span></h6>
                                                    </FormGroup>

                                                </Col>
                                                {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&

                                                    <Col className="mt-2" md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                        <button style={{ marginLeft: 3 }}
                                                            className="btn bg-primary text-light rounded"
                                                            onClick={this.handleAddOpen}>
                                                            NOUVEAU <i className="icon icon-add" />
                                                        </button>
                                                    </Col>
                                                }

                                            </Row>

                                            <BootstrapTable
                                                {...props.baseProps}
                                                //bootstrap4
                                                wrapperClasses="table-responsive"
                                                //selectRow={ selectRow }
                                                //defaultSorted={defaultSorted}
                                                pagination={paginationFactory()}
                                                defaultSorted={defaultSorted}
                                                striped
                                                hover
                                                condensed
                                                headerWrapperClasses="foo"
                                                noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                            //filter={filterFactory()}
                                            />

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }


                <Modal
                    width={600}
                    visible={isListArticleVisible}
                    title="Liste Article"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModal}
                    footer={[
                        /* <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateApprovisionnement}>
                             Enrégistrer
                         </Button>,*/
                        <Button key="back" type="danger" onClick={this.openListArticleModal}>
                            Fermer
                        </Button>,

                    ]}
                >
                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                {
                                    articles && articles.length ?
                                        articles.map((article, k) => {
                                            return <ListGroupItem key={k} id={k} onClick={(e) => this.openDetailArticleModal(article)} style={{ cursor: "pointer" }}>{article.designation}  </ListGroupItem>
                                        }) : null
                                }
                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>


                <Modal
                    width={600}
                    visible={isListArticleVisibleUpdate}
                    title="Liste Article"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModalUpdate}
                    footer={[
                        /* <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateApprovisionnement}>
                             Enrégistrer
                         </Button>,*/
                        <Button key="back" type="danger" onClick={this.openListArticleModalUpdate}>
                            Fermer
                        </Button>,

                    ]}
                >
                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                {
                                    articles && articles.length ?
                                        articles.map((article, k) => {
                                            return <ListGroupItem key={k} id={k} onClick={(e) => this.openDetailArticleModalUpdate(article)} style={{ cursor: "pointer" }}>{article.designation}  </ListGroupItem>
                                        }) : null
                                }
                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>



                <Modal
                    width={900}
                    visible={isAddModalVisible}
                    title="Ajouter un Approvisionnement"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddAprovisionnement}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        {/*<Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codeEntre">Commande N°</Label>
                                    <Input type="number" name="codeEntre" id="codeEntre" value={codeEntre} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateEntree">Date entrée</Label>
                                    <Input disabled type="text" name="dateEntree" value={dateEntree} id="dateEntree" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        */}



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFactFour">Facture Fournisseur N°</Label>
                                    <Input type="text" name="refFactFour" value={refFactFour} onChange={this.handleChange} id="refFactFour" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codFour">Fournisseur</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codFour}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '85%' }}
                                            placeholder="Selectionner Fournisseur"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeCategorie}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                fournisseurs && fournisseurs.length ?
                                                    fournisseurs.map((fournisseur, k) => {
                                                        return <Option value={fournisseur.codFour}>{fournisseur.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddFournisseur} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                        </Row>

                        {/* <Row form>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="modRegl">Type règlement</Label>
                                    <Select className="gx-mr-3 gx-mb-3" value={modRegl} onChange={this.handleChangeTypeReglement}>
                                        <Option value="Cash">Cash</Option>
                                        <Option value="Partiel">Partiel</Option>
                                        <Option value="Crédit">Crédit</Option>

                                    </Select>
                                    {//(modRegl === "A" || modRegl === "C" || modRegl === "E" || modRegl === "F") &&
                                        <h6 style={{ color: "black" }}><span>NB:Si la commande n'est pas payée à  l'avance, veuillez stipuler pour le Type de Règlement Crédit</span></h6>
                                    }

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codmod">Mode règlement</Label>
                                    <Select className="gx-mr-3 gx-mb-3" value={codmod} onChange={this.handleChangeModReglem}>
                                        <Option value="AUTRE">Autre</Option>
                                        <Option value="CARTE">Carte Bancaire</Option>
                                        <Option value="CHEQUE">Chèque</Option>
                                        <Option value="ESPECES">Espèces</Option>
                                        <Option value="MOMO">Mobile Money</Option>
                                        <Option value="VIREMENT">Virement</Option>
                                    </Select>

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="idemBal">Montant règlement</Label>
                                    <Input type="text" name="idemBal" value="champ non retrouvé" id="idemBal" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                                */}

                        <Row>
                            <Col md={3}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Article</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={5}>
                                <FormItem label="Montant règlement">
                                    <Input disabled type="number" name="netaPayer" value={numberWithCommas(netaPayer)} id="netaPayer" onChange={this.handleChange} />
                                </FormItem>
                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            {/**<th className="text-center"> </th> */}
                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Prix Unitaire</th>
                                            <th className="text-center">Quantité</th>
                                            <th className="text-center">Motant</th>
                                            {/*<th className="text-center">Tva</th>*/}
                                            {/*} <th className="text-center">Clé de Répartition</th>
                                            <th className="text-center">PU de Revient</th>
                                **/}
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>
                                                            {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{numberWithCommas(article.puEntree)}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{numberWithCommas(article.qtEntree * article.puEntree)}</td>
                                                            {/*(article.codTva === "A" || article.codTva === "C" || article.codTva === "E" || article.codTva === "F") &&
                                                                <td className="text-center">0</td>
                                                            }
                                                            {(article.codTva === "B" || article.codTva === "D") &&
                                                                <td className="text-center">18</td>
                                                        */ }
                                                            {/* <td className="text-center">{cleRepartition}</td>
                                                            <td className="text-center">{parseFloat(cleRepartition) + parseFloat(article.puEntree)}</td>
                                                        */}
                                                            <Button className="text-center ml-2" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                        {/* <tr style={{ backgroundColor: '#e4e7ea' }}>
                            <td></td>
                            <td></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center"></td>
                            <td className="text-center" style={{ backgroundColor: '#708090', color: 'white', fontWeight: 'bold' }}>{totalArticleMontant}</td>
                        </tr> */}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        {/*  <ToolkitProvider
                            keyField="id"
                            data={listeArticlesSelected}
                            columns={columnsTableauxArticles}
                            search
                            exportCSV

                        >
                            {
                                props => (
                                    <div>


                                        <Row className=" d-flex justify-content-center align-items-center mb-4">
                                            <Col md={6}>
                                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Article</button>
                                                {/*<i classNames="fa fa-search" />
                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    className="form-control form-control-md"
                                                    style={{ maxWidth: 450, width: '200%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher Matériels .."
                                />/}

                                            </Col>

                                            <Col md={6}>
                                                <div className=" form-button text-right" >

                                                </div>
                                                {/* {bureaux && bureaux !== null
                       &&
                       <div >
                         { loading && loading === true ? 
                         <div>
                        <Spinner color="primary" />
                        <Spinner color="secondary" />
                        <Spinner color="success" />
                        <Spinner color="danger" />
                        </div>
                        :                          
                        
                         }
                        </div>
                      }
                       {/* <button className="btn waves-effect waves-light btn-rounded btn-outline-success ml-1" onClick={this.handleClose}>Commencer <i className="fa fa-edit" /></button> 
                      </div> /}
                                            </Col>

                                        </Row>


                                        <Row style={{ maxHeight: 500, overflow: "auto" }}>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4
                                                keyField='codPro'
                                                data={listeArticlesSelected}
                                                columns={columnsTableauxArticles}
                                                filter={filterFactory()}
                                                wrapperClasses="table-responsive"
                                                //pagination={paginationFactory()}
                                                //onTableChange={this.afterSaveCell}
                                                cellEdit={cellEditFactory({
                                                    mode: 'click',
                                                    blurToSave: true,
                                                    //autoSelectText: true,

                                                })}

                                            />
                                        </Row>
                                    </div>
                                )
                            }
                        </ToolkitProvider> */}


                        {/*
                        <Row className="mt-3">
                            <Col md={5}>
                                <Card title="Groupe de Taxation et TVA" className="gx-card ">
                                    <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                {/**<th className="text-center"> </th> *}

                                                <th className="text-center">Base</th>
                                                <th className="text-center">Tva</th>
                                                <th className="text-center">Montant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                    listeArticlesSelected.map((article, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> /}

                                                                <td className="text-center">{article.qtEntree * article.puEntree}</td>

                                                                {(article.codTva === "A" || article.codTva === "C" || article.codTva === "E" || article.codTva === "F") &&
                                                                    <td className="text-center">0</td>
                                                                }
                                                                {(article.codTva === "B" || article.codTva === "D") &&
                                                                    <td className="text-center">18</td>
                                                                }



                                                                {(article.codTva === "A" || article.codTva === "C" || article.codTva === "E" || article.codTva === "F") &&
                                                                    <td className="text-center">0</td>
                                                                }
                                                                {(article.codTva === "B" || article.codTva === "D") &&
                                                                    <td className="text-center">{article.puEntree * article.qtEntree * 0.18}</td>
                                                                }


                                                            </tr>
                                                        );
                                                    })
                                                    : null
                                            }

                                        </tbody>
                                    </Table>
                                    <FormItem className="mt-3" label="Total TVA"  {...formItemLayout}>
                                        <Input disabled type="number" name="totalTva" value={totalTva} id="totalTva" onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>

                            </Col>
                            <Col md={7}>
                                <Card title="Totaux et Taxes" className="gx-card">
                                    <FormItem label="Net HT"  {...formItemLayout}>

                                        <Input disabled type="number" name="netHT" id="netHT" value={netHT} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="AIB"  {...formItemLayout}>

                                        <InputNumber name="tauxAib" id="tauxAib"
                                            value={tauxAib}
                                            onChange={this.handleChangeAib}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            defaultValue={0}
                                            min={0}
                                            max={100} />
                                        <Input type="number" name="montantAib" id="montantAib" value={montantAib} onChange={this.handleChange} placeholder="Montant AIB" />
                                    </FormItem>
                                    <FormItem label="Total TVA"  {...formItemLayout}>

                                        <Input disabled type="number" name="totalTva" value={totalTva} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    {/*<FormItem label="Frais Transport"  {...formItemLayout}>

                                        <Input type="number" name="fraisTransport" id="fraisTransport" value={fraisTransport} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    /}
                                    <FormItem label="Net à Payer"  {...formItemLayout}>

                                        <Input disabled type="number" name="netaPayer" id="netaPayer" value={netaPayer} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>


                            </Col>
                        </Row>
                                        */}

                    </Form>
                </Modal>

                <Modal
                    width={900}
                    visible={isUpdateModalVisible}
                    title="Modifier l'Approvisionnement"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateApprovisionnement}>
                            Modifier
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codeEntre">Commande N°</Label>
                                    <Input disabled type="number" name="codeEntre" id="codeEntre" value={codeEntre} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateEntree">Date Approvisionnement</Label>
                                    <Input disabled type="text" name="dateEntree" value={dateEntree} id="dateEntree" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFactFour">Facture Fournisseur N°</Label>
                                    <Input type="text" name="refFactFour" value={refFactFour} onChange={this.handleChange} id="refFactFour" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codFour">Fournisseur</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codFour}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '85%' }}
                                            placeholder="Selectionner Fournisseur"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeCategorie}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                fournisseurs && fournisseurs.length ?
                                                    fournisseurs.map((fournisseur, k) => {
                                                        return <Option value={fournisseur.codFour}>{fournisseur.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddFournisseur} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModalUpdate}><i className="icon icon-add" /> Ajouter Article</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={5}>
                                <FormItem label="Montant règlement">
                                    <Input disabled type="number" name="netaPayer" value={netaPayer} id="netaPayer" onChange={this.handleChange} />
                                </FormItem>
                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            {/**<th className="text-center"> </th> */}
                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Prix Unitaire</th>
                                            <th className="text-center">Quantité</th>
                                            <th className="text-center">Motant</th>
                                            {/*<th className="text-center">Tva</th>*/}
                                            {/*} <th className="text-center">Clé de Répartition</th>
                                            <th className="text-center">PU de Revient</th>
                                **/}
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>
                                                            {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.puEntree}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{article.qtEntree * article.puEntree}</td>
                                                            {/*(article.codTva === "A" || article.codTva === "C" || article.codTva === "E" || article.codTva === "F") &&
                                                                <td className="text-center">0</td>
                                                            }
                                                            {(article.codTva === "B" || article.codTva === "D") &&
                                                                <td className="text-center">18</td>
                                                        */ }
                                                            {/* <td className="text-center">{cleRepartition}</td>
                                                            <td className="text-center">{parseFloat(cleRepartition) + parseFloat(article.puEntree)}</td>
                                                        */}
                                                            <Button className="text-center ml-2" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>


                    </Form>
                </Modal>

                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Approvisionnement"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="codeEntre">Commande N°</Label>
                                    <Input disabled type="number" name="codeEntre" id="codeEntre" value={codeEntre} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="dateEntree">Date entrée</Label>
                                    <Input disabled type="text" name="dateEntree" value={dateEntree} id="dateEntree" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="datValid">Date validation</Label>
                                    <Input disabled type="text" name="datValid" value={datValid} id="datValid" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFactFour">Facture Fournisseur N°</Label>
                                    <Input disabled type="text" name="refFactFour" value={refFactFour} onChange={this.handleChange} id="refFactFour" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input disabled type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="codFour">Fournisseur</Label>
                                    <InputGroup compact>

                                        <Select
                                            disabled
                                            value={codFour}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '85%' }}
                                            placeholder="Selectionner Fournisseur"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeCategorie}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                fournisseurs && fournisseurs.length ?
                                                    fournisseurs.map((fournisseur, k) => {
                                                        return <Option value={fournisseur.codFour}>{fournisseur.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button disabled onClick={this.handleCancelAddFournisseur} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <button disabled className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Article</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={5}>
                                <FormItem label="Montant règlement">
                                    <Input disabled type="number" name="netaPayer" value={netaPayer} id="netaPayer" onChange={this.handleChange} />
                                </FormItem>
                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            {/**<th className="text-center"> </th> */}
                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Prix Unitaire</th>
                                            <th className="text-center">Quantité</th>
                                            <th className="text-center">Motant</th>
                                            {/*<th className="text-center">Tva</th>*/}
                                            {/*} <th className="text-center">Clé de Répartition</th>
                                            <th className="text-center">PU de Revient</th>
                                **/}
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>
                                                            {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.puEntree}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{article.qtEntree * article.puEntree}</td>
                                                            {/*(article.codTva === "A" || article.codTva === "C" || article.codTva === "E" || article.codTva === "F") &&
                                                                <td className="text-center">0</td>
                                                            }
                                                            {(article.codTva === "B" || article.codTva === "D") &&
                                                                <td className="text-center">18</td>
                                                        */ }
                                                            {/* <td className="text-center">{cleRepartition}</td>
                                                            <td className="text-center">{parseFloat(cleRepartition) + parseFloat(article.puEntree)}</td>
                                                        */}
                                                            <Button disabled className="text-center ml-2" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isAddFournisseurModalVisible}
                    title="Ajouter un Fournisseur"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAddFournisseur}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddFournisseur}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAddFournisseur}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            {/* <Col md={6}>
                                <FormGroup>
                                    <Label for="codFour">Code Fournisseur</Label>
                                    <Input type="number" name="codFour" id="codFour" value={codFour} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                </Col>*/}
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="numIfu">IFU</Label>
                                    <Input type="text" name="numIfu" value={numIfu} id="numIfu" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="raisonSociale">Raison sociale</Label>
                            <Input type="text" name="raisonSociale" value={raisonSociale} onChange={this.handleChange} id="raisonSociale" placeholder="" />
                        </FormGroup>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adrFour">Adresse</Label>
                                    <Input type="text" name="adrFour" id="adrFour" value={adrFour} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contactFour">Téléphone</Label>
                                    <Input type="text" name="contactFour" value={contactFour} id="contactFour" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup >
                                    <Label for="emailFour">Email</Label>
                                    <Input type="text" name="emailFour" value={emailFour} id="emailFour" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteWeb">Site web</Label>
                                    <Input type="text" name="siteWeb" value={siteWeb} id="siteWeb" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>
                </Modal>



                <Modal
                    width={500}
                    visible={isDetailsArticleVisible}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModal}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleArticleSelected}>
                            Ajouter
                        </Button>,
                        <Button key="back" type="danger" onClick={this.closeDetailArticleModal}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="puEntree">Prix Unitaire</Label>
                                    <Input type="number" name="puEntree" value={puEntree} onChange={this.handleChange} id="puEntree" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="qtEntree">Quantité</Label>
                                    <Input type="number" name="qtEntree" id="qtEntree" value={qtEntree} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                            {/*<Col md={12}>
                                <FormGroup >
                                    <Label for="codTva">TVA Vente</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={codTva} onChange={this.handleChangeTvaVente}>
                                        <Option value="A">A</Option>
                                        <Option value="B">B</Option>
                                        <Option value="C">C</Option>
                                        <Option value="D">D</Option>
                                        <Option value="E">E</Option>
                                        <Option value="F">F</Option>
                                    </Select>
                                    {(codTva === "A" || codTva === "C" || codTva === "E" || codTva === "F") &&
                                        <h6 style={{ color: "red" }}><span>Taux: 0%</span></h6>
                                    }
                                    {(codTva === "B" || codTva === "D") &&
                                        <h6 style={{ color: "red" }}><span>Taux: 18%</span></h6>
                                    }

                                </FormGroup>
                                </Col>*/}

                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={500}
                    visible={isDetailsArticleVisibleUpdate}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModalUpdate}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleArticleSelectedUpdate}>
                            Ajouter
                        </Button>,
                        <Button key="back" type="danger" onClick={this.closeDetailArticleModalUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="puEntree">Prix Unitaire</Label>
                                    <Input type="number" name="puEntree" value={puEntree} onChange={this.handleChange} id="puEntree" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="qtEntree">Quantité</Label>
                                    <Input type="number" name="qtEntree" id="qtEntree" value={qtEntree} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                            {/*<Col md={12}>
                                <FormGroup >
                                    <Label for="codTva">TVA Vente</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={codTva} onChange={this.handleChangeTvaVente}>
                                        <Option value="A">A</Option>
                                        <Option value="B">B</Option>
                                        <Option value="C">C</Option>
                                        <Option value="D">D</Option>
                                        <Option value="E">E</Option>
                                        <Option value="F">F</Option>
                                    </Select>
                                    {(codTva === "A" || codTva === "C" || codTva === "E" || codTva === "F") &&
                                        <h6 style={{ color: "red" }}><span>Taux: 0%</span></h6>
                                    }
                                    {(codTva === "B" || codTva === "D") &&
                                        <h6 style={{ color: "red" }}><span>Taux: 18%</span></h6>
                                    }

                                </FormGroup>
                                </Col>*/}

                        </Row>
                    </Form>
                </Modal>


                <Modal
                    width={400}
                    visible={isValidationApprovisionnement}
                    title="Validation Approvisionnement"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseValidation}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onDoubleClick={this.handleValiderApprovisionnement}>
                            Valider
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCloseValidation}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <h5>
                        Voulez vous valider cet Approvisionnement?<br />

                    </h5>
                    <p style={{ color: "red" }}>Si OUI, veuillez double cliquer sur le boutton VALIDER</p>
                </Modal>

            </div>
        )
    }
}


export default Approvisionnements;
